import Vue from "vue";
import VueRouter from "vue-router";

//import i18n from "@/i18n";
import Home from "@/views/Home.vue";
import Root from "@/router/Root";
import Typography from "@/views/Typography";
import Container from "@/views/Container";
import Form from "@/views/Form";
import Spinner from "@/views/Spinner";
import UIComponents from "@/views/UIComponents";
import Hero from "@/views/Hero";
import About from "@/views/About";
import Contact from "@/views/Contact";
import Content from "@/views/Content";
import Testimonials from "@/views/Testimonials";

import Master from "@/views/pages/Master";
import Landingpage from "@/views/pages/Landingpage";
import ProfileDashboard from "@/views/pages/ProfileDashboard";
import ProfileDashboardWelcome from "@/views/pages/ProfileDashboardWelcome";
import ProfileMatches from "@/views/pages/ProfileMatches";
import ProfileInviteFriends from "@/views/pages/ProfileInviteFriends";
import ProfileOverview from "@/views/pages/ProfileOverview";
import ProfileSettings from "@/views/pages/ProfileSettings";
import Login from "@/views/pages/Login";
import LoginAuthentification from "@/views/pages/LoginAuthentification";
import PasswordForgotten from "@/views/pages/PasswordForgotten";
import PasswordNextSteps from "@/views/pages/PasswordNextSteps";
import PasswordNew from "@/views/pages/PasswordNew";
import PasswordSuccess from "@/views/pages/PasswordSuccess";
import Registration from "@/views/pages/Registration";
import WizardStep1 from "@/views/pages/WizardStep1";
import WizardStep2 from "@/views/pages/WizardStep2";
import WizardStep3 from "@/views/pages/WizardStep3";
import FAQ from "@/views/pages/FAQ";
import DataPrivacy from "@/views/pages/DataPrivacy";
import RegistrationSuccess from "@/views/pages/RegistrationSuccess";
import ProfilePublic from "@/views/pages/ProfilePublic";
import ProfilePauseParticipation from "@/views/pages/ProfilePauseParticipation";
import ProfileEditParticipation from "@/views/pages/ProfileEditParticipation";
import ProfileExtendPause from "@/views/pages/ProfileExtendPause";
import ProfileUpload from "@/views/pages/ProfileUpload";
import OffboardingFeedback from "@/views/pages/OffboardingFeedback";
import OffboardingSuccess from "@/views/pages/OffboardingSuccess";
import SurveyRating from "@/views/pages/SurveyRating";
import SurveySuccess from "@/views/pages/SurveySuccess";
import SurveyPlanned from "@/views/pages/SurveyPlanned";
import SurveyNoMeeting from "@/views/pages/SurveyNoMeeting";

import Landingpage2D from "@/views/pages/Landingpage2D";
import Landingpage3D from "@/views/pages/Landingpage3D";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        component: Root,
        children: [
            {
                path: "",
                name: "Home",
                component: Home
            },
            {
                path: "typography",
                name: "Typography",
                component: Typography
            },
            {
                path: "container",
                name: "Container",
                component: Container
            },
            {
                path: "form",
                name: "Form",
                component: Form
            },
            {
                path: "spinner",
                name: "Spinner",
                component: Spinner
            },
            {
                path: "uicomponents",
                name: "UIComponents",
                component: UIComponents
            },
            {
                path: "hero",
                name: "Hero",
                component: Hero
            },
            {
                path: "about",
                name: "About",
                component: About
            },
            {
                path: "contact",
                name: "Contact",
                component: Contact
            },
            {
                path: "content",
                name: "Content",
                component: Content
            },
            {
                path: "master",
                name: "Master",
                component: Master
            },
            {
                path: "landingpage",
                name: "Landingpage",
                component: Landingpage
            },
            {
                path: "profileDashboard",
                name: "ProfileDashboard",
                component: ProfileDashboard
            },
            {
                path: "profileDashboardWelcome",
                name: "ProfileDashboardWelcome",
                component: ProfileDashboardWelcome
            },
            {
                path: "testimonials",
                name: "Testimonials",
                component: Testimonials
            },
            {
                path: "login",
                name: "Login",
                component: Login
            },
            {
                path: "loginAuthentification",
                name: "LoginAuthentification",
                component: LoginAuthentification
            },
            {
                path: "passwordForgotten",
                name: "PasswordForgotten",
                component: PasswordForgotten
            },
            {
                path: "passwordNextSteps",
                name: "PasswordNextSteps",
                component: PasswordNextSteps
            },
            {
                path: "passwordNew",
                name: "PasswordNew",
                component: PasswordNew
            },
            {
                path: "passwordSuccess",
                name: "PasswordSuccess",
                component: PasswordSuccess
            },
            {
                path: "registration",
                name: "Registration",
                component: Registration
            },
            {
                path: "wizardStep1",
                name: "WizardStep1",
                component: WizardStep1
            },
            {
                path: "wizardStep2",
                name: "WizardStep2",
                component: WizardStep2
            },
            {
                path: "wizardStep3",
                name: "WizardStep3",
                component: WizardStep3
            },
            {
                path: "faq",
                name: "FAQ",
                component: FAQ
            },
            {
                path: "dataPrivacy",
                name: "DataPrivacy",
                component: DataPrivacy
            },
            {
                path: "registrationSuccess",
                name: "RegistrationSuccess",
                component: RegistrationSuccess
            },
            {
                path: "profileMatches",
                name: "ProfileMatches",
                component: ProfileMatches
            },
            {
                path: "profileInviteFriends",
                name: "ProfileInviteFriends",
                component: ProfileInviteFriends
            },
            {
                path: "profileOverview",
                name: "ProfileOverview",
                component: ProfileOverview
            },
            {
                path: "profileSettings",
                name: "ProfileSettings",
                component: ProfileSettings
            },
            {
                path: "profilePublic",
                name: "ProfilePublic",
                component: ProfilePublic
            },
            {
                path: "profilePauseParticipation",
                name: "ProfilePauseParticipation",
                component: ProfilePauseParticipation
            },
            {
                path: "profileEditParticipation",
                name: "ProfileEditParticipation",
                component: ProfileEditParticipation
            },
            {
                path: "profileExtendPause",
                name: "ProfileExtendPause",
                component: ProfileExtendPause
            },
            {
                path: "profileUpload",
                name: "ProfileUpload",
                component: ProfileUpload
            },
            {
                path: "offboardingFeedback",
                name: "OffboardingFeedback",
                component: OffboardingFeedback
            },
            {
                path: "offboardingSuccess",
                name: "OffboardingSuccess",
                component: OffboardingSuccess
            },
            {
                path: "surveyRating",
                name: "SurveyRating",
                component: SurveyRating
            },
            {
                path: "surveySuccess",
                name: "SurveySuccess",
                component: SurveySuccess
            },
            {
                path: "surveyPlanned",
                name: "SurveyPlanned",
                component: SurveyPlanned
            },
            {
                path: "surveyNoMeeting",
                name: "SurveyNoMeeting",
                component: SurveyNoMeeting
            },
            {
                path: "landingpage2D",
                name: "Landingpage2D",
                component: Landingpage2D
            },
            {
                path: "landingpage3D",
                name: "Landingpage3D",
                component: Landingpage3D
            }
        ]
    },
];

const router = new VueRouter({
    routes
});

// See https://github.com/PhraseApp-Blog/vue-i18n-demo/blob/master/src/router/index.js
/*
router.beforeEach((to, from, next) => {
    console.log("Router: Before switch from " + (from.name || "n/a") + " with locale " + (from.params.locale || "n/a") + " to " + to.name + " with locale " + to.params.locale);

    if (to.params.locale === from.params.locale) {
        console.log("Router: Proceed");
        next();
        return;
    }

    if (!isSupportedLocale(to.params.locale)) {
        console.log("Router: Locale " + to.params.locale + " is not supported - updating to default locale " + getStartingLocale());
        next({name: to.name, params: {...to.params, locale: getStartingLocale()}});
        return;
    }

    console.log("Router: Setting locale " + to.params.locale);
    setLocale(to);

    console.log("Router: Proceed");
    next();
});*/

export default router;
