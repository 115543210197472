<template>
  <div>
    <AppNavigation />

    <div class="page-links">
      <h1>Pages</h1>
      <ul>
        <li>
          <router-link :to="{ name: 'Master'}" target= '_blank' class="testing">Master</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Landingpage'}" target= '_blank' class="testing">Landingpage</router-link>
        </li>
      </ul>

      <h2>Registration</h2>
      <ul>
        <li>
          <router-link :to="{ name: 'Registration'}" target= '_blank' class="testing">Registration</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'WizardStep1'}" target= '_blank' class="testing">Wizard (step 1)</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'WizardStep2'}" target= '_blank' class="testing">Wizard (step 2)</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'WizardStep3'}" target= '_blank' class="testing">Wizard (step 3)</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'RegistrationSuccess'}" target= '_blank' class="testing">Success</router-link>
        </li>
      </ul>

      <h2>Password</h2>
      <ul>
        <li>
          <router-link :to="{ name: 'PasswordForgotten'}" target= '_blank' class="testing">Password (forgotten)</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'PasswordNextSteps'}" target= '_blank' class="testing">Next steps</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'PasswordNew'}" target= '_blank' class="testing">New password</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'PasswordSuccess'}" target= '_blank' class="testing">Success</router-link>
        </li>
      </ul>

      <h2>Login</h2>
      <ul>
        <li>
          <router-link :to="{ name: 'Login'}" target= '_blank' class="testing">Login</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'LoginAuthentification'}" target= '_blank' class="testing">Authentification</router-link>
        </li>
      </ul>

      <h2>Profile</h2>
      <ul>
        <li>
          <router-link :to="{ name: 'ProfileDashboard'}" target= '_blank' class="testing">Dashboard</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'ProfileMatches'}" target= '_blank' class="testing">Matches</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'ProfileOverview'}" target= '_blank' class="testing">Profile</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'ProfilePublic'}" target= '_blank' class="testing">Profile (public)</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'ProfileUpload'}" target= '_blank' class="progress">Profile Upload</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'ProfileInviteFriends'}" target= '_blank' class="testing">Invite friends</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'ProfileSettings'}" target= '_blank' class="testing">Settings</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'ProfilePauseParticipation'}" target= '_blank' class="testing">Pause Participation</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'ProfileEditParticipation'}" target= '_blank' class="testing">Edit Participation</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'ProfileExtendPause'}" target= '_blank' class="testing">Extend Pause</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'ProfileDashboardWelcome'}" target= '_blank' class="testing">Profile Welcome</router-link>
        </li>
      </ul>

      <h2>Offboarding</h2>
      <ul>
        <li>
          <router-link :to="{ name: 'OffboardingFeedback'}" target= '_blank' class="testing">Offboarding Feedback</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'OffboardingSuccess'}" target= '_blank' class="testing">Success</router-link>
        </li>
      </ul>

      <h2>Survey</h2>
      <ul>
        <li>
          <router-link :to="{ name: 'SurveyRating'}" target= '_blank' class="testing">Survey Rating</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'SurveyPlanned'}" target= '_blank' class="testing">Survey Planned</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'SurveyNoMeeting'}" target= '_blank' class="testing">Survey Not Planned</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'SurveySuccess'}" target= '_blank' class="testing">Success</router-link>
        </li>
      </ul>

      <h2>Content</h2>
      <ul>
        <li>
          <router-link :to="{ name: 'FAQ'}" target= '_blank' class="testing">FAQs</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'DataPrivacy'}" target= '_blank' class="testing">Data Privacy</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import AppNavigation from "@/components/AppNavigation";

export default {
  name: "Home",
  components: {AppNavigation}
}
</script>

<style scoped lang="scss">
  @import "../assets/scss/config/variables";

  .page-links {
    max-width: 43.75rem;
    margin: 100px auto;
    background: #f5f5f5;
    padding: 30px;
    border-radius: 10px;

    ul {
      margin: 30px 0;
    }

    li {
      margin: 0 0 15px 0;
    }

    a {
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  .progress {
    color: #ff7942;
    font-weight: bold;
  }

  .testing {
    color: #36b1ff;
    font-weight: bold;
  }

  .done {
    color: #14b341;
    font-weight: bold;
  }
</style>