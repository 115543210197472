<template>
  <div class="page">
    <Header/>

    <main class="main">

    </main>
    <Support/>
    <Footer/>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Support from "@/components/Support";

export default {
  name: "Master",
  components: {Header, Footer, Support}
}
</script>

<style lang="scss" scoped>

</style>