<template>
  <!-- modal -->
  <div class="modal-backdrop is--active"><!-- is--active -->
    <div class="modal">
      <div class="modal__box" role="dialog" id="modalUpload" aria-labelledby="modalHeadlineUpload" aria-modal="true" tabindex="-1"><!-- is--hidden -->
        <div class="modal__header">
          <h2 class="modal__headline" id="modalHeadlineUpload">Profilbild hochladen</h2>

          <button class="btn-icon modal__close" type="button">
            <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" width="20" height="20" fill="currentColor">
              <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/>
            </svg>
            <span class="visually-hidden">Close</span>
          </button>
        </div>
        <!-- todo: check about form tag & content replacement in modal nuxt. -->
        <form>
          <div class="modal__body">
              droparea
          </div>
          <div class="modal__action">
            <button class="btn btn--primary" type="submit">Speichern</button>
          </div>
        </form>
      </div>
    </div>
  </div><!-- ./modal -->
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
@import "../assets/scss/config/variables";

</style>