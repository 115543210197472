<template>
  <div class="card">
    <div class="card__header">
      <div class="card__headline">
        <!-- todo: check with Timar 'is-headline' span vs. h2, h3 - can we pass which kind of element to be used? -->
        <span class="card__headline-label">Bisherige Matches</span>
      </div>
    </div>

    <div class="card__body">
      <ul class="previous-match">
        <li class="previous-match__item">
          <img class="previous-match__img" src="https://picsum.photos/id/177/50/50" width="50" height="50" alt="Amy Carlson">
          <div class="previous-match__info">
            <span class="previous-match__name">Amy Carlson</span>
            <a class="previous-match__link" href="#">Profil ansehen</a>
          </div>
        </li>
        <li class="previous-match__item">
          <img class="previous-match__img" src="https://picsum.photos/id/178/50/50" width="50" height="50" alt="Trevor Santiago">
          <div class="previous-match__info">
            <span class="previous-match__name">Trevor Santiago</span>
            <a class="previous-match__link" href="#">Profil ansehen</a>
          </div>
        </li>
        <li class="previous-match__item is--disabled">
          <img class="previous-match__img" src="https://picsum.photos/id/179/50/50" width="50" height="50" alt="User name">
          <div class="previous-match__info">
            <span class="previous-match__name">Jeremy Figueroa</span>
            <span class="previous-match__text">Profil nicht verfügbar</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="card__footer card__footer--push-down">
      <div class="card__actions">
        <a class="btn btn--primary" href="#">Zu den Matches</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
  @import "../assets/scss/config/variables";

  .previous-match {
    list-style: none;
    padding: 0;
    max-width: 19.375rem;
    margin: 0 auto;
  }

  .previous-match__item {
    display: flex;
    align-items: center;
    gap: 1.25rem;

    &:not(:first-child) {
      margin: 1.875rem 0 0 0;
    }
  }

  .previous-match__img {
    border-radius: 50%;
    flex: 0 0 3.125rem;
    align-self: flex-start;

    .is--disabled & {
      opacity: 0.3;
    }
  }

  .previous-match__info {
    display: inline-flex;
    flex-direction: column;
    gap: 0.625rem;
  }

  .previous-match__name {
    font-weight: 700;
    line-height: 1.3;

    .is--disabled & {
      opacity: 0.7;
    }
  }

  .previous-match__link {
    text-decoration: underline;
    color: $gray-mm;
    font-weight: 700;
    font-size: 0.875rem;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .previous-match__text {
    font-size: 0.875rem;
    line-height: 1.5;
  }
</style>