<template>
  <div class="page">
    <Header/>

    <main class="main has--gray-bg">
      <!-- wizard -->
      <div class="wizard">
        <div class="wizard__inner">
          <!-- progress -->
          <div class="wizard__progress">
            <progress class="progress" id="file" value="50" max="100">42%</progress>
          </div><!-- ./progress -->

          <!-- form -->
          <form class="wizard__question">
            <div class="form__row">
              <div class="form__field">
                <label for="ddlCompany" class="label label--big">
                  <span class="label__text">Wann hast du bei company angefangen?</span>
                </label>
                <select id="ddlCompany" class="select" required>
                  <option value="Option 1">Option 1</option>
                  <option value="Option 2">Option 2</option>
                  <option value="Option 3">Option 3</option>
                  <option value="Option 4">Option 4</option>
                  <option value="Option 5">Option 5</option>
                  <option value="Option length">Option that has too long of a value to fit</option>
                </select>
              </div>
            </div>

            <!-- navigation -->
            <div class="wizard__navigation">
              <a class="btn btn--secondary wizard__link" href="#/wizardStep2">
                <svg class="wizard__link-icon" aria-labelledby="wizardBack" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" width="20" height="24" fill="currentColor">
                  <title id="wizardBack">Zurück</title>
                  <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"/>
                </svg>
                <span class="wizard__link-text">Zurück</span>
              </a>

              <button class="btn btn--primary wizard__link" type="submit">
                <svg class="wizard__link-icon" aria-labelledby="wizardBack" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="24" height="24" fill="currentColor">
                  <path d="M243.2 189.9V258c26.1 5.9 49.3 15.6 73.6 22.3v-68.2c-26-5.8-49.4-15.5-73.6-22.2zm223.3-123c-34.3 15.9-76.5 31.9-117 31.9C296 98.8 251.7 64 184.3 64c-25 0-47.3 4.4-68 12 2.8-7.3 4.1-15.2 3.6-23.6C118.1 24 94.8 1.2 66.3 0 34.3-1.3 8 24.3 8 56c0 19 9.5 35.8 24 45.9V488c0 13.3 10.7 24 24 24h16c13.3 0 24-10.7 24-24v-94.4c28.3-12.1 63.6-22.1 114.4-22.1 53.6 0 97.8 34.8 165.2 34.8 48.2 0 86.7-16.3 122.5-40.9 8.7-6 13.8-15.8 13.8-26.4V95.9c.1-23.3-24.2-38.8-45.4-29zM169.6 325.5c-25.8 2.7-50 8.2-73.6 16.6v-70.5c26.2-9.3 47.5-15 73.6-17.4zM464 191c-23.6 9.8-46.3 19.5-73.6 23.9V286c24.8-3.4 51.4-11.8 73.6-26v70.5c-25.1 16.1-48.5 24.7-73.6 27.1V286c-27 3.7-47.9 1.5-73.6-5.6v67.4c-23.9-7.4-47.3-16.7-73.6-21.3V258c-19.7-4.4-40.8-6.8-73.6-3.8v-70c-22.4 3.1-44.6 10.2-73.6 20.9v-70.5c33.2-12.2 50.1-19.8 73.6-22v71.6c27-3.7 48.4-1.3 73.6 5.7v-67.4c23.7 7.4 47.2 16.7 73.6 21.3v68.4c23.7 5.3 47.6 6.9 73.6 2.7V143c27-4.8 52.3-13.6 73.6-22.5z"/>
                </svg>
                <span class="wizard__link-text">Fertig!</span>
              </button>
            </div><!-- ./navigation -->
          </form><!-- ./form -->
        </div>
      </div><!-- ./split panel -->
    </main>

    <Support/>
    <Footer/>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Support from "@/components/Support";

export default {
  name: "Registration",
  components: {Header, Footer, Support}
}
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/config/variables";
  @import "../../assets/scss/components/wizard";

</style>