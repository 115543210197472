<template>
  <div class="about">
    <div class="about__inner">
      <!-- text -->
      <div class="about__content copy-text">
        <h2 class="h1">Ein Kaffeeplausch, der Dich motiviert.</h2>
        <p>
          Sag »Tschüss« zu Deiner (Home)Office- oder Projekt-Bubble und sag »Hallo« zu neuen Perspektiven, Ideen und
          persönlichen Kontakten – beim virtuellen Kaffeeplausch mit Kolleg*innen, die Du neu kennenlernst.
        </p>
        <p>
          Tauscht Euch aus, sprecht über Dinge, die Euch interessieren und erfahre mehr über Deine Kolleg*innen, ihre
          Aufgaben und den Bereich, in dem sie arbeiten.
        </p>
      </div><!-- ./text -->

      <!-- steps -->
      <div class="about__steps">
        <div class="about__item">
          <!--<svg class="about__visual" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="220" height="190">
            <path d="M127.1 146.5c1.3 7.7 8 13.5 16 13.5h16.5c9.8 0 17.6-8.5 16.3-18-3.8-28.2-16.4-54.2-36.6-74.7-14.4-14.7-23.6-33.3-26.4-53.5C111.8 5.9 105 0 96.8 0H80.4C70.6 0 63 8.5 64.1 18c3.9 31.9 18 61.3 40.6 84.4 12 12.2 19.7 27.5 22.4 44.1zm112 0c1.3 7.7 8 13.5 16 13.5h16.5c9.8 0 17.6-8.5 16.3-18-3.8-28.2-16.4-54.2-36.6-74.7-14.4-14.7-23.6-33.3-26.4-53.5C223.8 5.9 217 0 208.8 0h-16.4c-9.8 0-17.5 8.5-16.3 18 3.9 31.9 18 61.3 40.6 84.4 12 12.2 19.7 27.5 22.4 44.1zM400 192H32c-17.7 0-32 14.3-32 32v192c0 53 43 96 96 96h192c53 0 96-43 96-96h16c61.8 0 112-50.2 112-112s-50.2-112-112-112zm0 160h-16v-96h16c26.5 0 48 21.5 48 48s-21.5 48-48 48z"/>
          </svg>-->
          <img class="about__visual" src="https://via.placeholder.com/220x190" width="220" height="190" loading="lazy" alt="">
          <div class="about__text copy-text">
            <h3 class="about__headline">It's simple</h3>
            <p>
              Melde Dich an und erzähle uns ein wenig von Dir.
            </p>
          </div>
        </div>
        <div class="about__item">
          <img class="about__visual" src="https://via.placeholder.com/220x190" width="220" height="190" loading="lazy" alt="">
          <div class="about__text copy-text">
            <h3 class="about__headline">It's magic</h3>
            <p>
              Unser smarter Algorithmus ermittelt für Dich das passende Match.
            </p>
          </div>
        </div>
        <div class="about__item">
          <img class="about__visual" src="https://via.placeholder.com/220x190" width="220" height="190" loading="lazy" alt="">
          <div class="about__text copy-text">
            <h3 class="about__headline">It's fun</h3>
            <p>
              Die Einladung flattert direkt ins Postfach und Ihr trefft Euch virtuell zum Mystery Coffee.
            </p>
          </div>
        </div>
      </div><!-- ./steps -->

      <!-- text
      <div class="about__content copy-text">
        <h3 class="h2">Einfach Dein Netzwerk erweitern.</h3>
        <p>
          Nutze das Potenzial neuer Begegnungen und komme mit Kolleg*innen ins Gespräch, denen Du sonst nie begegnest.
          Mystery Coffee bringt Euch zusammen – ganz entspannt bei einem virtuellen Kaffee.
        </p>
        <p>
          Tauscht Euch aus, sprecht über Dinge, die Euch interessieren und erfahre mehr über Deine Kolleg*innen, ihre
          Aufgaben und den Bereich, in dem sie arbeiten.
        </p>
      </div>--><!-- ./text -->
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
  @import "../assets/scss/config/variables";

  .about {
    background: #fff;
    padding: $space-lg $page-padding;

    @media (min-width: $breakpoint-md-up) {
      padding: $space-xl $page-padding;
    }
  }

  .about__inner {
    max-width: $container-lg;
    margin: 0 auto;

    display: flex;
    align-items: center;
    flex-direction: column;
    gap: $space-lg;
  }

  .about__content {
    max-width: $container-md;
    text-align: center;
  }

  // steps
  .about__steps {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    gap: 30px;

    @media (min-width: $breakpoint-lg-up) {
      grid-template-columns: repeat(3,1fr);
    }
  }

  .about__item {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background: $gray-light;
    border-radius: $border-radius-lg;
    padding: $box-padding-lg;
  }

  .about__headline {
    font-size: 1.625rem;
  }

  .about__text {
    margin: 1.875rem 0 0 0;
    max-width: $container-xs;
  }
</style>