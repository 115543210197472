<template>
  <div class="page">
    <Header/>

    <main class="main">
      <FAQs/>
      <ContactInfo/>
    </main>

    <Support/>
    <Footer/>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Support from "@/components/Support";
import ContactInfo from "@/components/ContactInfo";
import FAQs from "@/components/FAQs";

export default {
  name: "FAQ",
  components: {FAQs, ContactInfo, Header, Footer, Support}
}
</script>

<style lang="scss" scoped>

</style>