<template>
  <div>
    <AppNavigation/>
    <ContentBlock/>
  </div>
</template>

<script>
import ContentBlock from "@/components/ContentBlock";
import AppNavigation from "@/components/AppNavigation";

export default {
  name: "Content",
  components: {AppNavigation, ContentBlock}
}
</script>

<style scoped>

</style>