<template>
  <div class="page">
    <HeaderProfile />

    <main class="main has--gray-bg">
      <div class="profile">
        <div class="profile__inner">
          <ProfileNavigation/>

          <!-- grid -->
          <div class="grid">
            <div class="grid__headline">
              <h1 class="h2">Account Einstellungen</h1>
            </div>

            <!-- email -->
            <div class="grid__item grid__item--big">
              <!-- todo: make sure to set the role group and labelledby attribute correctly -->
              <div class="card" role="group" aria-labelledby="settingEmail">
                <!-- header -->
                <div class="card__header">
                  <div id="settingEmail" class="card__headline">
                    <span class="card__headline-label">E-Mail</span>
                  </div>
                  <div class="card__edit">
                    <button class="btn-icon">
                      <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16" height="16" fill="currentColor">
                        <path d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z"/>
                      </svg>
                      <span class="visually-hidden">Bearbeiten</span>
                    </button>
                  </div>
                </div><!-- ./header -->

                <div class="card__body">
                  <b>joshua@company.de</b>
                </div>
              </div>
            </div>

            <div class="grid__item grid__item--big">
              <!-- todo: add the class is--editing if the user edits a block -->
              <div class="card is--editing" role="group" aria-labelledby="settingEmailEdit">
                <!-- header -->
                <div class="card__header">
                  <div id="settingEmailEdit" class="card__headline">
                    <span class="card__headline-label">E-Mail</span>
                  </div>
                  <div class="card__edit">
                    <button class="btn-icon">
                      <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" width="14" height="20" fill="currentColor">
                        <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/>
                      </svg>
                      <span class="visually-hidden">Schließen</span>
                    </button>
                  </div>
                </div><!-- ./header -->

                <!-- body -->
                <div class="card__body">
                  <!-- current email -->
                  <div class="form__row">
                    <div class="form__field">
                      <div class="label">
                        <span class="label__text">Aktuelle E-Mail-Adresse</span>
                      </div>
                      <p>joshua@company.de</p>
                    </div>
                  </div><!-- ./current email -->

                  <!-- new email -->
                  <div class="form__row">
                    <div class="form__field">
                      <label for="settings-email" class="label">
                        <span class="label__text">Neue E-Mail-Adresse</span>
                      </label>

                      <!-- todo: set aria-invalid and aria-describedby on error -->
                      <input type="email" id="settings-email" name="email" autocomplete="email" class="text-input" autocapitalize="none" spellcheck="false" aria-invalid="true" aria-describedby="newEmailErrorMsg" required>

                      <!-- error message -->
                      <div class="field__error" id="newEmailErrorMsg">
                        <svg aria-hidden="true" focusable="false" class="field__error-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="16" height="16" fill="currentColor">
                          <path
                              d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"/>
                        </svg>

                        <span class="field__error-text">Error message</span>
                      </div><!-- ./error message -->
                    </div>
                  </div><!-- ./new email -->
                </div>
                <!-- ./body -->

                <!-- submit -->
                <div class="card__submit">
                  <button class="btn btn--primary" type="submit">Speichern</button>
                </div>
                <!-- ./submit -->
              </div>
            </div><!-- ./email -->

            <!-- password -->
            <div class="grid__item grid__item--big">
              <!-- todo: make sure to set the role group and labelledby attribute correctly -->
              <div class="card" role="group" aria-labelledby="settingPassword">
                <!-- header -->
                <div class="card__header">
                  <div id="settingPassword" class="card__headline">
                    <span class="card__headline-label">Passwort</span>
                  </div>
                  <div class="card__edit">
                    <button class="btn-icon">
                      <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16" height="16" fill="currentColor">
                        <path d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z"/>
                      </svg>
                      <span class="visually-hidden">Bearbeiten</span>
                    </button>
                  </div>
                </div><!-- ./header -->

                <div class="card__body">
                  <div class="password-bullets" aria-hidden="true">
                    <span class="password-bullets__circle"></span>
                    <span class="password-bullets__circle"></span>
                    <span class="password-bullets__circle"></span>
                    <span class="password-bullets__circle"></span>
                    <span class="password-bullets__circle"></span>
                    <span class="password-bullets__circle"></span>
                    <span class="password-bullets__circle"></span>
                    <span class="password-bullets__circle"></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid__item grid__item--big">
              <!-- todo: add the class is--editing if the user edits a block -->
              <div class="card is--editing" role="group" aria-labelledby="settingPasswordEdit">
                <!-- header -->
                <div class="card__header">
                  <div id="settingPasswordEdit" class="card__headline">
                    <span class="card__headline-label">Passwort</span>
                  </div>
                  <div class="card__edit">
                    <button class="btn-icon">
                      <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" width="14" height="20" fill="currentColor">
                        <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/>
                      </svg>
                      <span class="visually-hidden">Schließen</span>
                    </button>
                  </div>
                </div><!-- ./header -->

                <!-- body -->
                <div class="card__body">
                  <!-- current password -->
                  <div class="form__row">
                    <div class="form__field">
                      <label for="password-current" class="label">
                        <span class="label__text">Altes Passwort</span>
                      </label>

                      <!-- reveal -->
                      <div class="password-reveal">
                        <input type="password" id="password-current" name="password" autocomplete="current-password" class="text-input" aria-invalid="false" aria-describedby="currentPassowrdErrorMsg" required>

                        <button class="password-reveal__toggle" type="button">
                          <svg class="password-reveal__svg" aria-describedby="password-new-info"
                               xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" role="img" width="20"
                               fill="currentColor">
                            <path
                                d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"/>
                          </svg>

                          <svg class="password-reveal__svg" aria-describedby="password-new-info"
                               xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" role="img" width="20"
                               fill="currentColor">
                            <path
                                d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"/>
                          </svg>

                          <!-- todo vuejs: switch text when on show/hide -->
                          <span id="password-new-info" class="visually-hidden">Show</span>
                        </button>
                      </div><!-- ./reveal -->

                      <!-- error message -->
                      <div class="field__error" id="currentPassowrdErrorMsg" style="display: none;">
                        <svg aria-hidden="true" focusable="false" class="field__error-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="16" height="16" fill="currentColor">
                          <path
                              d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"/>
                        </svg>

                        <span class="field__error-text">Passwort entspricht nicht...</span>
                      </div><!-- ./error message -->
                    </div>
                  </div><!-- ./current password -->

                  <!-- new password -->
                  <div class="form__row">
                    <div class="form__field">
                      <label for="password-new" class="label">
                        <span class="label__text">Neues Passwort</span>
                        <span class="label__hint">Verwende bitte mindestens 6 Zeichen, darunter einen Buchstaben und eine Zahl.</span>
                      </label>

                      <!-- reveal -->
                      <div class="password-reveal">
                        <input type="password" id="password-new" name="password-new" autocomplete="new-password" class="text-input" aria-invalid="true" aria-describedby="newPassowrdErrorMsg" required>

                        <button class="password-reveal__toggle" type="button">
                          <svg class="password-reveal__svg" aria-describedby="password-info"
                               xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" role="img" width="20"
                               fill="currentColor">
                            <path
                                d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"/>
                          </svg>

                          <svg class="password-reveal__svg" aria-describedby="password-info"
                               xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" role="img" width="20"
                               fill="currentColor">
                            <path
                                d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"/>
                          </svg>

                          <!-- todo vuejs: switch text when on show/hide -->
                          <span id="password-info" class="visually-hidden">Show</span>
                        </button>
                      </div><!-- ./reveal -->

                      <!-- error message -->
                      <div class="field__error" id="newPassowrdErrorMsg">
                        <svg aria-hidden="true" focusable="false" class="field__error-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="16" height="16" fill="currentColor">
                          <path
                              d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"/>
                        </svg>

                        <span class="field__error-text">Passwort entspricht nicht...</span>
                      </div><!-- ./error message -->

                      <div
                          style="padding: 0 5px; font-size: 14px; border-radius: 10px; background: #CACED8; margin: 10px 0 0 0;">
                        &nbsp;
                      </div>

                      <div class="password-generate">
                        <button class="password-generate__button" type="button">
                          <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" width="14" height="14">
                            <path d="M512 176.001C512 273.203 433.202 352 336 352c-11.22 0-22.19-1.062-32.827-3.069l-24.012 27.014A23.999 23.999 0 0 1 261.223 384H224v40c0 13.255-10.745 24-24 24h-40v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24v-78.059c0-6.365 2.529-12.47 7.029-16.971l161.802-161.802C163.108 213.814 160 195.271 160 176 160 78.798 238.797.001 335.999 0 433.488-.001 512 78.511 512 176.001zM336 128c0 26.51 21.49 48 48 48s48-21.49 48-48-21.49-48-48-48-48 21.49-48 48z"/>
                          </svg>
                          Sicheres Passwort generieren
                        </button>
                      </div>
                    </div>
                  </div><!-- ./new password -->
                </div>
                <!-- ./body -->

                <!-- submit -->
                <div class="card__submit">
                  <button class="btn btn--primary" type="submit">Speichern</button>
                </div>
                <!-- ./submit -->
              </div>
            </div>
            <!-- ./password -->

            <!-- language -->
            <div class="grid__item grid__item--big">
              <!-- todo: make sure to set the role group and labelledby attribute correctly -->
              <div class="card" role="group" aria-labelledby="settingLanguage">
                <!-- header -->
                <div class="card__header">
                  <div id="settingLanguage" class="card__headline">
                    <span class="card__headline-label">Sprache</span>
                  </div>
                  <div class="card__edit">
                    <button class="btn-icon">
                      <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16" height="16" fill="currentColor">
                        <path d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z"/>
                      </svg>
                      <span class="visually-hidden">Bearbeiten</span>
                    </button>
                  </div>
                </div><!-- ./header -->

                <div class="card__body">
                  <ul class="chips-list">
                    <li class="chips-list__item">Deutsch</li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="grid__item grid__item--big">
              <!-- todo: add the class is--editing if the user edits a block -->
              <div class="card is--editing" role="group" aria-labelledby="settingLanguageEdit">
                <!-- header -->
                <div class="card__header">
                  <div id="settingLanguageEdit" class="card__headline">
                    <span class="card__headline-label">Sprache</span>
                  </div>
                  <div class="card__edit">
                    <button class="btn-icon">
                      <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" width="14" height="20" fill="currentColor">
                        <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/>
                      </svg>
                      <span class="visually-hidden">Schließen</span>
                    </button>
                  </div>
                </div><!-- ./header -->

                <!-- body -->
                <div class="card__body">
                  <div class="chip-container">
                    <div class="chip">
                      <input class="chip__input" type="radio" name="systemLanguage" id="chipGerman" checked>
                      <label class="chip__label" for="chipGerman">Deutsch</label>
                    </div>
                    <div class="chip">
                      <input class="chip__input" type="radio" name="systemLanguage" id="chipEnglish">
                      <label class="chip__label" for="chipEnglish">Englisch</label>
                    </div>
                    <div class="chip">
                      <input class="chip__input" type="radio" name="systemLanguage" id="chipItalian">
                      <label class="chip__label" for="chipItalian">Italienisch</label>
                    </div>
                  </div>
                </div>
                <!-- ./body -->

                <!-- submit -->
                <div class="card__submit">
                  <button class="btn btn--primary" type="submit">Speichern</button>
                </div>
                <!-- ./submit -->
              </div>
            </div><!-- ./language -->

            <!-- delete -->
            <div class="grid__item grid__item--big">
              <!-- todo: make sure to set the role group and labelledby attribute correctly -->
              <div class="card" role="group" aria-labelledby="settingDelete">
                <!-- header -->
                <div class="card__header">
                  <div id="settingDelete" class="card__headline">
                    <span class="card__headline-label">Profil löschen</span>
                  </div>
                  <div class="card__edit">
                    <button class="btn-icon">
                      <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16" height="16" fill="currentColor">
                        <path d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z"/>
                      </svg>
                      <span class="visually-hidden">Bearbeiten</span>
                    </button>
                  </div>
                </div><!-- ./header -->

                <div class="card__body">
                  <p>
                    <b>Möchtest du Mystery Coffee nicht mehr nutzen?</b>
                  </p>
                </div>
              </div>
            </div>

            <div class="grid__item grid__item--big">
              <!-- todo: add the class is--editing if the user edits a block -->
              <div class="card is--editing" role="group" aria-labelledby="settingDeleteEdit">
                <!-- header -->
                <div class="card__header">
                  <div id="settingDeleteEdit" class="card__headline">
                    <span class="card__headline-label">Profil löschen</span>
                  </div>
                  <div class="card__edit">
                    <button class="btn-icon">
                      <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" width="14" height="20" fill="currentColor">
                        <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/>
                      </svg>
                      <span class="visually-hidden">Schließen</span>
                    </button>
                  </div>
                </div><!-- ./header -->

                <!-- body -->
                <div class="card__body">
                  <p>
                    Bist Du sicher? Eine Kommunikation ohne Mystery Coffee ist möglich, aber weniger spannend und abwechslungsreich. Wenn Du nur eine Pause brauchst, kannst Du Deine Teilnahme einfach nur pausieren.
                  </p>
                </div>
                <!-- ./body -->

                <!-- submit -->
                <div class="card__submit">
                  <button class="btn btn--primary" type="submit">Pause einlegen</button>
                  <button class="btn btn--secondary" type="submit">Account löschen</button>
                </div>
                <!-- ./submit -->
              </div>
            </div><!-- ./delete -->
          </div><!-- ./grid -->
        </div>
      </div>
    </main>

    <Support/>
    <Footer/>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import HeaderProfile from "@/components/HeaderProfile";
import ProfileNavigation from "@/components/ProfileNavigation";
import Support from "@/components/Support";

export default {
  name: "Master",
  components: {ProfileNavigation, HeaderProfile, Footer, Support}
}
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/config/variables";
  @import "../../assets/scss/components/profile";

  .password-bullets {
    display: inline-grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 4px;
  }

  .password-bullets__circle {
    width: 6px;
    height: 6px;
    background: $text-color;
    border-radius: 50%;
  }
</style>