<template>
  <div class="page">
    <Header/>

    <main class="main has--gray-bg">
      <!-- wizard -->
      <div class="wizard">
        <div class="wizard__inner">
          <img class="wizard__image" src="https://via.placeholder.com/150x150" width="150" height="150" alt="">

          <!-- header -->
          <div class="wizard__header copy-text">
            <h1 class="h2">Ist das Treffen noch geplant?</h1>
          </div><!-- ./header -->

          <!-- form -->
          <form class="wizard__question">
            <!-- navigation -->
            <div class="wizard__navigation">
              <a class="btn btn--secondary" href="#/surveyNoMeeting">Nein</a>
              <button class="btn btn--primary wizard__link" type="submit">Ja</button>
            </div><!-- ./navigation -->
          </form><!-- ./form -->
        </div>
      </div><!-- ./split panel -->
    </main>

    <Support/>
    <Footer/>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Support from "@/components/Support";

export default {
  name: "SurveyPlanned",
  components: {Header, Footer, Support}
}
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/config/variables";
  @import "../../assets/scss/components/wizard";

</style>