<template>
  <div>
    <AppNavigation/>
    <HeroLandingpage />
  </div>
</template>

<script>
import HeroLandingpage from "@/components/HeroLandingpage";
import AppNavigation from "@/components/AppNavigation";

export default {
  name: "Hero",
  components: {AppNavigation, HeroLandingpage}
}
</script>

<style scoped>

</style>