<template>
  <div class="page">
    <HeaderProfile />

    <main class="main has--gray-bg">
      <div class="profile">
        <div class="profile__inner">

          <ProfileNavigation/>

          <h1 class="visually-hidden">Anstehende Matches</h1>

          <!-- grid -->
          <div class="grid">
            <div class="grid__item grid__item--small">
              <CardCurrentMatch/>
            </div>

            <div class="grid__item grid__item--big">
              <CardNextMatch/>
            </div>
          </div><!-- ./grid -->

          <!-- grid -->
          <div class="grid">
            <div class="grid__headline">
              <!-- todo: maybe headline needs 10px margin to bottom. -->
              <h2>Bisherige Matches</h2>
            </div>
            <div class="grid__item grid__item--small">
              <CardPreviousMatch/>
            </div>
            <div class="grid__item grid__item--small">
              <CardPreviousMatchRating/>
            </div>
            <div class="grid__item grid__item--small">
              <CardPreviousMatchDisabled/>
            </div>
            <div class="grid__item grid__item--small">
              <CardPreviousMatch/>
            </div>
          </div><!-- ./grid -->
        </div>
      </div>
    </main>

    <Support/>
    <Footer/>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import HeaderProfile from "@/components/HeaderProfile";
import ProfileNavigation from "@/components/ProfileNavigation";
import CardCurrentMatch from "@/components/CardCurrentMatch";
import CardNextMatch from "@/components/CardNextMatch";
import CardPreviousMatch from "@/components/CardPreviousMatch";
import Support from "@/components/Support";
import CardPreviousMatchRating from "@/components/CardPreviousMatchRating";
import CardPreviousMatchDisabled from "@/components/CardPreviousMatchDisabled";

export default {
  name: "Master",
  components: {
    CardPreviousMatchDisabled,
    CardPreviousMatchRating,
    CardPreviousMatch, CardNextMatch, CardCurrentMatch, ProfileNavigation, HeaderProfile, Footer, Support}
}
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/config/variables";
  @import "../../assets/scss/components/profile";
</style>