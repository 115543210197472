<template>
  <div class="page">
    <HeaderInProcess/>

    <main class="main has--gradient">
      <!-- split panel -->
      <div class="split-panel">
        <div class="split-panel__inner">
          <div class="split-panel__item split-panel--visual">
            <!--<img class="split-panel__image" src="https://via.placeholder.com/400x400" width="400" height="400" alt="">-->

            <img class="split-panel__image" src="/img/dummy/2d.png" alt="Illustration of Mystery Cup">
          </div>
          <div class="split-panel__item split-panel--content">
            <div class="split-panel__content">
              <h1 class="split-panel__headline h2">Passwort anfordern</h1>

              <!-- form -->
              <form class="form" novalidate>
                <!-- email -->
                <div class="form__row">
                  <div class="form__field">
                    <label for="password-request-email" class="label">
                      <span class="label__text">E-Mail-Adresse</span>
                    </label>
                    <input type="email" id="password-request-email" name="email" autocomplete="email" class="text-input"
                           autocapitalize="none" spellcheck="false" required>
                  </div>
                </div><!-- ./email -->

                <!-- submit -->
                <div class="form__submit">
                  <button type="submit" class="btn btn--primary">Link anfordern</button>
                </div><!-- ./submit -->
              </form><!-- ./form -->

              <div class="form-addition">
                <p>
                  Erstes Mal hier? Jetzt <a href="#/registration">Profil erstellen</a>.
                </p>
                <p>
                  Du hast schon ein Profil? Jetzt <a href="#/login">Einloggen</a>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div><!-- ./split panel -->
    </main>

    <Support/>
    <Footer/>
  </div>
</template>

<script>
import HeaderInProcess from "@/components/HeaderInProcess";
import Footer from "@/components/Footer";
import Support from "@/components/Support";

export default {
  name: "PasswordForgotten",
  components: {HeaderInProcess, Footer, Support}
}
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/config/variables";
  @import "../../assets/scss/components/split-panel";
</style>