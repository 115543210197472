<template>
  <!-- modal -->
  <div class="modal-backdrop is--active"><!-- is--active -->
    <div class="modal">
      <div class="modal__box" role="dialog" id="modal1" aria-labelledby="welcomeHeadline" aria-modal="true" tabindex="-1"><!-- is--hidden -->
        <div class="modal__header">
          <button class="btn-icon modal__close" type="button">
            <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" width="20" height="20" fill="currentColor">
              <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/>
            </svg>
            <span class="visually-hidden">Close</span>
          </button>
        </div>
        <div class="modal__body">
          <div class="welcome">
            <h2 id="welcomeHeadline">Willkommen Joshua!</h2>
            <p>
              <b>Schön, dass du dabei bist...</b>
            </p>
            <p>
              Die nächste Auslosung findet statt am: 13.05.2021
            </p>
            <img class="welcome__img" src="https://via.placeholder.com/150x150" width="150" height="150" loading="lazy" alt="Illustration of Mystery Cup">

            <button class="btn btn--primary">Los geht's</button>
          </div>
        </div>
      </div>
    </div>
  </div><!-- ./modal -->
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
  @import "../assets/scss/config/variables";

  .welcome {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    margin: 0 0 $space-sm 0;
    text-align: center;
  }

  .welcome__leadin {
    font-weight: 700;
  }

  .welcome__img {
    margin: $space-sm 0;
  }
</style>