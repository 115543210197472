<template>
  <div class="page">
    <HeaderProfile />

    <main class="main has--gray-bg">
      <div class="profile">
        <div class="profile__inner">
          <ProfileNavigation/>

          <!-- grid -->
          <div class="grid">
            <div class="grid__item grid__item--big">
              <CardProfileDashboard/>
            </div>
            <div class="grid__item grid__item--small">
              <CardInviteFriends/>
            </div>

            <div class="grid__item grid__item--small">
              <CardCurrentMatch/>
            </div>

            <div class="grid__item grid__item--small">
              <CardNextMatch/>
            </div>

            <div class="grid__item grid__item--small">
              <CardPreviousMatches/>
            </div>
          </div><!-- ./grid -->
        </div>
      </div>
    </main>

    <ModalPauseParticipation/>

    <Support/>
    <Footer/>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import HeaderProfile from "@/components/HeaderProfile";
import ProfileNavigation from "@/components/ProfileNavigation";
import CardCurrentMatch from "@/components/CardCurrentMatch";
import CardNextMatch from "@/components/CardNextMatch";
import CardPreviousMatches from "@/components/CardPreviousMatches";
import Support from "@/components/Support";
import CardProfileDashboard from "@/components/CardProfileDashboard";
import CardInviteFriends from "@/components/CardInviteFriends";
import ModalPauseParticipation from "@/components/ModalPauseParticipation";

export default {
  name: "ProfilePauseParticipation",
  components: {
    ModalPauseParticipation,
    CardInviteFriends,
    CardProfileDashboard,
    CardPreviousMatches, CardNextMatch, CardCurrentMatch, ProfileNavigation, HeaderProfile, Footer, Support}
}
</script>

<style lang="scss">
  @import "../../assets/scss/config/variables";
  @import "../../assets/scss/components/profile";
</style>