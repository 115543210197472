<template>
  <!-- accordion -->
  <div class="accordion">
    <div class="accordion__inner">
      <div class="accordion__intro copy-text">
        <h1>FAQ</h1>
      </div>

      <div class="accordion__items">
        <!-- item -->
        <div class="accordion__item">
          <h2 class="accordion__headline">
            <button class="accordion__toggle" aria-expanded="true" aria-controls="faqSample" id="idfaqSample" type="button">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr

              <svg class="accordion__icon" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 320 512" width="12" fill="currentColor">
                <path
                    d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"/>
              </svg>
            </button>
          </h2>
          <div class="accordion__panel" id="faqSample" role="region" aria-labelledby="idfaqSample">
            <p>some content</p>
          </div>
        </div><!-- ./item -->

        <!-- todo: update aria-expanded. where do it? in method? -->
        <div  v-for="(faq, index) in faqs" :key="faq.id" class="accordion__item">
          <h2 class="accordion__headline">
            <button class="accordion__toggle" aria-expanded="false" aria-controls="`faq${index}`" :id="`idfaq${index}`" type="button" @click="toggleAccordion(index)">
              {{ faq.title }}

              <svg class="accordion__icon" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 320 512" width="12" fill="currentColor">
                <path
                    d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"/>
              </svg>
            </button>
          </h2>
          <div class="accordion__panel" :id="`faq${index}`" role="region" :aria-labelledby="`idfaq${index}`" hidden>
            <p>
              {{ faq.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div><!-- ./accordion -->
</template>

<script>
export default {
  name: "FAQs",
  data() {
    return {
      faqs: [
        {
          id: 0,
          title: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr?',
          description: 'some content'
        },
        {
          id: 1,
          title: 'Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet?',
          description: 'some content'
        },
        {
          id: 2,
          title: 'At vero eos et accusam et justo duo dolores et ea rebum clita kasd gubergren no sea takimata sanctus est?',
          description: 'some content'
        }
      ]
    }
  },
  methods: {
    toggleAccordion (index) {
      console.log("toggle accordion " + index);
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/scss/config/variables";
  @import "../assets/scss/components/accordion";
</style>