<template>
  <!-- header -->
  <header class="header">
    <div class="header__inner">
      <!-- logo -->
      <a class="logo" href="/">
        <img class="logo__image" src="/img/dummy/logoipsum-logo.svg" alt="Customer X Logo">
      </a><!-- ./logo -->

      <!-- actions -->
      <div class="header__actions">
        <!-- dropdown -->
        <div class="dropdown">
          <button class="dropdown__toggle" aria-haspopup="true" v-on:click="isVisible = !isVisible" :aria-expanded="[isVisible]" type="button" aria-label="Menu">
            <span class="dropdown__text">DE</span>

            <svg class="dropdown__icon" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="12" fill="currentColor">
              <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"/>
            </svg>
          </button>

          <div class="dropdown__menu" :class="{ 'is--visible': isVisible }">
            <ul class="dropdown__list">
              <li class="dropdown__item">
                <a class="dropdown__link" href="#">DE</a>
              </li>
              <li class="dropdown__item">
                <a class="dropdown__link" href="#">EN</a>
              </li>
              <li class="dropdown__item">
                <a class="dropdown__link" href="#">ES</a>
              </li>
            </ul>
          </div>
        </div><!-- ./dropdown -->
      </div><!-- ./actions -->
    </div>
  </header><!-- ./header -->
</template>

<script>
export default {
  data() {
    return {
      isVisible: false
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/scss/config/variables";
  @import "../assets/scss/components/header";
</style>