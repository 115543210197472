<template>
  <div>
    <AppNavigation/>
    <AboutSteps />
  </div>
</template>

<script>
import AboutSteps from "@/components/AboutSteps";
import AppNavigation from "@/components/AppNavigation";

export default {
  name: "About",
  components: {AppNavigation, AboutSteps}
}
</script>

<style scoped>

</style>