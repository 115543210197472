<template>
  <div class="page">
    <Header/>

    <main class="main">
      <HeroLandingpage2D/>
      <AboutSteps/>
      <TestimonialCarousel/>
      <ContactInfo/>
    </main>

    <Support/>
    <Footer/>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Support from "@/components/Support";
import HeroLandingpage2D from "@/components/HeroLandingpage2D";
import ContactInfo from "@/components/ContactInfo";
import AboutSteps from "@/components/AboutSteps";
import TestimonialCarousel from "@/components/TestimonialCarousel";

export default {
  name: "Master",
  components: {TestimonialCarousel, AboutSteps, ContactInfo, HeroLandingpage2D, Header, Footer, Support}
}
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/config/variables";
</style>