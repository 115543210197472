<template>
  <div class="testimonials">
    <div class="testimonials__inner">
      <!-- text -->
      <div class="testimonials__content copy-text">
        <h2 class="h1">Happy Mystery Coffee Fans</h2>
        <p>
          Für viele Deiner Kolleg*innen ist der Mystery Coffee schon zum regelmäßigen Ritual geworden. Werde auch Du
          Teil
          der Community und erlebe die Inspiration aus der Verbindung mit einzigartigen Menschen.
        </p>
      </div><!-- ./text -->

      <!-- carousel -->
      <div class="testimonials__carousel">
        <!-- prev -->
        <button class="testimonials__button" @click="previous()" type="button">
          <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"
               fill="currentColor" width="15">
            <path
                d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"/>
          </svg>
          <span class="visually-hidden">Previous slide</span>
        </button><!-- ./prev -->

        <!-- slides -->
        <flickity class="testimonial__slides" ref="flickity" :options="flickityOptions">
          <div class="testimonials__item">
            <!-- quote -->
            <figure class="quote">
              <img class="quote__image" width="120" height="120" src="https://picsum.photos/id/447/120/120" loading="lazy" alt="Some person">

              <blockquote class="quote__block copy-text">
                <p class="quote__text">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                  labore et dolore magna aliquyam erat, sed diam voluptua.
                </p>
              </blockquote>

              <figcaption class="quote__footer">
                <span class="quote__name">Jose Carpenter</span>
                <span class="quote__role">Developer</span>
              </figcaption>
            </figure><!-- ./quote -->
          </div>
          <div class="testimonials__item">
            <!-- quote -->
            <figure class="quote">
              <img class="quote__image" width="120" height="120" src="https://picsum.photos/id/1005/350/350" loading="lazy" alt="Some person">

              <blockquote class="quote__block copy-text">
                <p class="quote__text">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.
                </p>
              </blockquote>

              <figcaption class="quote__footer">
                <span class="quote__name">John Doe</span>
                <span class="quote__role">Manager</span>
              </figcaption>
            </figure><!-- ./quote -->
          </div>
        </flickity><!-- ./slides -->

        <!-- next -->
        <button class="testimonials__button" @click="next()" type="button">
          <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"
               fill="currentColor" width="15">
            <path
                d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"/>
          </svg>
          <span class="visually-hidden">Next slide</span>
        </button><!-- ./next -->
      </div><!-- ./carousel -->
    </div>
  </div>
</template>

<script>
import Flickity from 'vue-flickity';

export default {
  components: {
    Flickity
  },

  data() {
    return {
      flickityOptions: {
        initialIndex: 3,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        watchCSS: true // enable or disable slider
      }
    }
  },

  methods: {
    next() {
      this.$refs.flickity.next();
    },

    previous() {
      this.$refs.flickity.previous();
    }
  }
}
</script>

<style lang="scss">
  @import "../assets/scss/config/variables";
  @import "../../node_modules/flickity/css/flickity.css";

  .testimonials {
    background: var(--client-bg-color);
    color: var(--client-fg-color);
    padding: $space-lg $page-padding;

    @media (min-width: $breakpoint-md-up) {
      padding: $space-xl $page-padding;
    }
  }

  .testimonials__inner {
    max-width: $container-lg;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: $space-lg;
  }

  .testimonials__content {
    max-width: $container-md;
    text-align: center;
  }

  // <div> carousel
  .testimonials__carousel {
    width: 100%;
    max-width: 960px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (min-width: $breakpoint-md-up) {
      gap: 50px;
    }
  }

  // <div> slides (flickity)
  .testimonial__slides {
    overflow: hidden;
    width: 100%;
    border-radius: $border-radius-lg;

    // disable flickity for smaller screens
    // (connected to 'watchCSS' option from slider)
    &::after {
      content: '';
    }

    // enable flickity for larger devices
    // (connected to 'watchCSS' option from slider)
    @media (min-width: $breakpoint-md-up) {
      &::after {
        content: 'flickity';
        display: none;
      }
    }
  }

  // <button> next & prev
  .testimonials__button {
    display: none;

    @media (min-width: $breakpoint-md-up) {
      flex: 0 0 45px;
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: $transition;

      &:hover,
      &:focus {
        background: var(--client-bg-color-hover);
        color: var(--client-fg-color);
      }
    }
  }

  // <div> slide
  .testimonials__item {
    display: flex;
    align-items: stretch;
    width: 100%;
    height: auto;
    min-height: 100%;
    background: #fff;
    border-radius: $border-radius-lg;
    padding: $box-padding-lg;
    color: $text-color;
    margin: 0 0 30px 0;

    @media (min-width: $breakpoint-md-up) {
      margin: 0 10px;
    }
  }
</style>