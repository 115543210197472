<template>
  <!-- footer -->
  <footer class="footer">
    <div class="footer__inner">
      <!-- copyright -->
      <div class="copyright">
        <span class="copyright__text">powered by</span>
        <!-- todo: check if we have svg format -->
        <a class="copyright__logo" href="https://www.mysteryminds.com" target="_blank" rel="noopener noreferrer">
          <img src="/img/mysteryminds-logo.png" width="125" height="31" loading="lazy" alt="Mystery Minds Logo">
        </a>
      </div><!-- ./copyright -->

      <!-- navigation -->
      <nav class="resources" aria-label="Key.Aria.FooterNavigation">
        <ul class="resources__list">
          <li class="resources__item">
            <a class="resources__link" href="#">Impressum</a>
          </li>
          <li class="resources__item">
            <a class="resources__link" href="#">Datenschutz</a>
          </li>
          <li class="resources__item">
            <a class="resources__link" href="#">FAQ</a>
          </li>
        </ul>
      </nav><!-- ./navigation -->

      <!-- social -->
      <nav class="social-media" aria-label="Key.Social Media Links">
        <ul class="social-media__list">
          <li class="social-media__item">
            <a class="social-media__link" href="#" target="_blank" rel="noopener noreferrer">
              <svg class="social-media__icon" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height="15" fill="currentColor">
                <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"/>
              </svg>
              <span class="visually-hidden">Follow us on linkedIn</span>
            </a>
          </li>
          <li class="social-media__item">
            <a class="social-media__link" href="#" target="_blank" rel="noopener noreferrer">
              <svg class="social-media__icon" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" height="15" fill="currentColor">
                <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/>
              </svg>
              <span class="visually-hidden">Follow us on facebook</span>
            </a>
          </li>
        </ul>
      </nav><!-- ./social -->
    </div>
  </footer><!-- ./footer -->
</template>

<script>
export default {
}
</script>

<style lang="scss">
  @import "../assets/scss/config/variables";

  // footer
  .footer {
    width: 100%;
    padding: 1.875rem $page-padding $space-xxl;
    background: $gray-mm;
    color: #fff;

    @media (min-width: $breakpoint-lg-up) {
      padding: 1.875rem $page-padding;
    }
  }

  .footer__inner {
    display: grid;
    grid-row-gap: 20px;
    grid-column-gap: 15px;
    grid-template-rows: repeat(1, 1fr);
    margin: 0 auto;
    max-width: $container-lg;
    align-items: center;

    @media (min-width: $breakpoint-xl-up) {
      grid-template-columns: repeat(3, 1fr);
      place-content: center;
    }
  }

  .copyright {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    justify-content: center;

    @media (min-width: $breakpoint-xl-up) {
      justify-content: flex-start;
    }
  }

  .copyright__text {

  }

  .copyright__logo {
    max-width: 7.8125rem;
  }


  // resource links
  .resources {

  }

  .resources__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    column-gap: 1.875rem;
    row-gap: 15px;
  }

  .resources__item {

  }

  .resources__link {
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }


  // social media
  .social-media {
  }

  .social-media__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 0.625rem;
    justify-content: center;

    @media (min-width: $breakpoint-xl-up) {
      justify-content: flex-end;
    }
  }

  .social-media__item {

  }

  .social-media__link {
    width: 2.125rem;
    height: 2.125rem;
    border-radius: 50%;
    background: $gray-light;
    color: $gray-mm;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: $transition; // 0.25

    &:hover,
    &:focus {
      background: #fff;
      //box-shadow: 0 0 0.0625rem 0.125rem rgba(255,255,255,1);
    }
  }

  .social-media__icon {
    height: 15px;
    width: auto;
  }
</style>
