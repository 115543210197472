<template>
  <div class="page">
    <Header/>

    <main class="main has--gray-bg">
      <!-- wizard -->
      <div class="wizard">
        <div class="wizard__inner">
          <img class="wizard__image" src="https://via.placeholder.com/150x150" width="150" height="150" alt="">

          <!-- header -->
          <div class="wizard__header copy-text">
            <h1 class="h2">Was hat dir bei deinem letzten Mystery Coffee mit John am besten gefallen?</h1>
          </div><!-- ./header -->

          <!-- form -->
          <form class="wizard__question">
            <fieldset class="fieldset">
              <legend class="visually-hidden">Deine Bewertung</legend>

              <div class="form__row">
                <div class="form__field">
                  <input type="checkbox" class="checkbox" name="surveyReasons" id="surveyReason1">
                  <label for="surveyReason1" class="checkbox-label">Es war ein sehr höfliches Gespräch</label>
                </div>
                <div class="form__field">
                  <input type="checkbox" class="checkbox" name="v" id="surveyReason2">
                  <label for="surveyReason2" class="checkbox-label">Das Gespräch war sehr relevant für meinen täglichen Job</label>
                </div>
                <div class="form__field">
                  <input type="checkbox" class="checkbox" name="surveyReasons" id="surveyReason3">
                  <label for="surveyReason3" class="checkbox-label">Lorem ipsum dolor sit amet.</label>
                </div>
                <div class="form__field">
                  <input type="checkbox" class="checkbox" name="surveyReasons" id="surveyReason4">
                  <label for="surveyReason4" class="checkbox-label">Stet clita kasd gubergren, no sea takimata sanctus.</label>
                </div>
              </div>
            </fieldset>

            <!-- navigation -->
            <div class="wizard__navigation">
              <button class="btn btn--primary wizard__link" type="submit">Senden</button>
            </div><!-- ./navigation -->
          </form><!-- ./form -->
        </div>
      </div><!-- ./split panel -->
    </main>

    <Support/>
    <Footer/>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Support from "@/components/Support";

export default {
  name: "SurveyRating",
  components: {Header, Footer, Support}
}
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/config/variables";
  @import "../../assets/scss/components/wizard";

</style>