<template>
    <div id="app">
      <router-view/>
    </div>
</template>

<script>

export default {
    components: {}
}


</script>

<style lang="scss">
  @import './assets/scss/config/variables';
  @import "./assets/scss/global";

  [class^='container-'] {
    width: 100%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-left: $page-padding;
    padding-right: $page-padding;
  }

  // wide container with max-width
  .container-lg {
    max-width: $container-lg;
  }

  // medium
  .container-md {
    max-width: $container-md;
  }

  // small
  .container-sm {
    max-width: $container-sm;
  }

  // xsmall
  .container-xs {
    max-width: $container-xs;
  }
</style>
