<template>
  <div>
    <AppNavigation/>

    <div class="container-lg" style="margin-bottom: 50px; padding-bottom: 50px; border-bottom: 1px solid #ccc;">
      <h1>Form</h1>
    </div>

    <!-- alert -->
    <div class="container-lg" style="margin-bottom: 50px; padding-bottom: 50px; border-bottom: 1px solid #ccc;">
      <h2>Alert</h2>
      <p>
        Info aria-live: 'assertive' - use with (urgent) error / success / warning messages. Otherwise use 'polite'
        (info).<br>
        The container with attributes aria-live and aria-atomic should be present at all times.
      </p>

      <!-- error -->
      <div aria-live="assertive" aria-atomic="true">
        <div class="alert alert--error" tabindex="-1" aria-labelledby="error-summary-heading">
          <svg class="alert__icon" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 576 512" fill="currentColor" width="24" height="22">
            <path
                d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"/>
          </svg>
          <div class="alert__message" id="error-summary-heading">
            <span class="alert__headline">
              Error
            </span>
            <p class="alert__description">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
              et dolore.
            </p>
          </div>
        </div>
      </div><!-- ./error -->

      <!-- warning -->
      <div aria-live="assertive" aria-atomic="true">
        <div class="alert alert--warning" tabindex="-1" aria-labelledby="warning-summary-heading">
          <svg class="alert__icon" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 448 512" fill="currentColor" width="24" height="24">
            <path
                d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z"/>
          </svg>
          <div class="alert__message" id="warning-summary-heading">
            <span class="alert__headline">
              Warning
            </span>
            <p class="alert__description">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
              et dolore.
            </p>
          </div>
        </div>
      </div><!-- ./warning -->

      <!-- info -->
      <div aria-live="polite" aria-atomic="true">
        <div class="alert alert--info" tabindex="-1" aria-labelledby="info-summary-heading">
          <svg class="alert__icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
               fill="currentColor" width="24" height="24">
            <path
                d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"/>
          </svg>
          <div class="alert__message" id="info-summary-heading">
            <span class="alert__headline">
              Info
            </span>
            <p class="alert__description">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
              et dolore.
            </p>
          </div>
        </div>
      </div><!-- ./info -->

      <!-- success -->
      <div aria-live="assertive" aria-atomic="true">
        <div class="alert alert--success" tabindex="-1" aria-labelledby="success-summary-heading">
          <svg class="alert__icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
               fill="currentColor" width="24" height="24">
            <path
                d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"/>
          </svg>
          <div class="alert__message" id="success-summary-heading">
            <span class="alert__headline">
              Success
            </span>
            <p class="alert__description">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
              et dolore.
            </p>
          </div>
        </div>
      </div><!-- ./success -->
    </div><!-- ./alert -->

    <!-- fieldset & legend -->
    <div class="container-lg" style="margin-bottom: 50px; padding-bottom: 50px; border-bottom: 1px solid #ccc;">
      <h2>Fieldset & Legend</h2>
      <p>

      </p>

      <fieldset class="fieldset">
        <legend class="fieldset__legend">Legend</legend>
        <div>some content</div>
      </fieldset>
    </div><!-- ./fieldset & legend -->

    <!-- label -->
    <div class="container-lg" style="margin-bottom: 50px; padding-bottom: 50px; border-bottom: 1px solid #ccc;">
      <h2>Label</h2>
      <br>
      <label for="" class="label">
        <span class="label__text">Label</span>
        <span class="label__hint">Lorem ipsum dolor sit amet</span>
      </label>

      <br><br>

      <label for="" class="label label--big">
        <span class="label__text">Label</span>
        <span class="label__hint">Lorem ipsum dolor sit amet</span>
      </label>
    </div><!-- ./label -->

    <!-- text input -->
    <div class="container-lg" style="margin-bottom: 50px; padding-bottom: 50px; border-bottom: 1px solid #ccc;">
      <h2>Text input</h2>
      <p>

      </p>

      <input type="text" class="text-input" value="default" required>

      <br><br>

      <input type="text" class="text-input" value="readonly" readonly>

      <br><br>

      <input type="text" class="text-input" value="disabled" disabled>

      <br><br>

      <input type="text" class="text-input is--invalid" value="error" aria-invalid="true">

      <br><br><br><br>

      <textarea rows="3" class="text-input">default</textarea>

      <br><br>

      <textarea rows="3" class="text-input" readonly>readonly</textarea>

      <br><br>

      <textarea rows="3" class="text-input" disabled>disabled</textarea>

      <br><br>

      <textarea rows="3" class="text-input is--invalid" aria-invalid="true">error</textarea>
    </div><!-- ./text input -->

    <!-- select -->
    <div class="container-lg" style="margin-bottom: 50px; padding-bottom: 50px; border-bottom: 1px solid #ccc;">
      <h2>Select</h2>
      <p>

      </p>

      <select class="select" required>
        <option value="Option 1">Option 1</option>
        <option value="Option 2">Option 2</option>
        <option value="Option 3">Option 3</option>
        <option value="Option 4">Option 4</option>
        <option value="Option 5">Option 5</option>
        <option value="Option length">Option that has too long of a value to fit</option>
      </select>

      <br><br>

      <select class="select" disabled>
        <option value="Option 1">Option 1</option>
        <option value="Option 2">Option 2</option>
        <option value="Option 3">Option 3</option>
        <option value="Option 4">Option 4</option>
        <option value="Option 5">Option 5</option>
        <option value="Option length">Option that has too long of a value to fit</option>
      </select>

      <br><br>

      <select class="select is--invalid" aria-invalid="true">
        <option value="Option 1">Option 1</option>
        <option value="Option 2">Option 2</option>
        <option value="Option 3">Option 3</option>
        <option value="Option 4">Option 4</option>
        <option value="Option 5">Option 5</option>
        <option value="Option length">Option that has too long of a value to fit</option>
      </select>
    </div><!-- ./select -->

    <!-- radio -->
    <div class="container-lg" style="margin-bottom: 50px; padding-bottom: 50px; border-bottom: 1px solid #ccc;">
      <h2>Radio</h2>
      <p></p>
      <div>
        <input type="radio" class="radio" id="rb1" name="radio" checked>
        <label for="rb1" class="radio-label">Radio button</label>
      </div>
      <br>
      <div>
        <input type="radio" class="radio" id="rb2" name="radio">
        <label for="rb2" class="radio-label">Radio button</label>
      </div>

      <br>

      <div>
        <input type="radio" class="radio" id="rbDisabled" disabled name="asdf">
        <label for="rbDisabled" class="radio-label">Radio button (disabled)</label>
      </div>

      <br>

      <div>
        <input type="radio" class="radio" id="rbDisabledChecked" disabled checked>
        <label for="rbDisabledChecked" class="radio-label">Radio button (disabled)</label>
      </div>

      <br>

      <div>
        <input type="radio" class="radio is--invalid" id="rbError" aria-invalid="true">
        <label for="rbError" class="radio-label">Radio button (error)</label>
      </div>

    </div><!-- ./radio -->

    <!-- checkbox -->
    <div class="container-lg" style="margin-bottom: 50px; padding-bottom: 50px; border-bottom: 1px solid #ccc;">
      <h2>Checkbox</h2>
      <p></p>
      <div>
        <input type="checkbox" class="checkbox" id="cb1">
        <label for="cb1" class="checkbox-label">Checkbox (default)</label>
      </div>

      <br>

      <div>
        <input type="checkbox" class="checkbox" id="cbDisabled" disabled>
        <label for="cbDisabled" class="checkbox-label">Checkbox (disabled)</label>
      </div>

      <br>

      <div>
        <input type="checkbox" class="checkbox" id="cbDisabledChecked" disabled checked>
        <label for="cbDisabledChecked" class="checkbox-label">Checkbox (disabled)</label>
      </div>

      <br>

      <div>
        <input type="checkbox" class="checkbox is--invalid" id="cbError" aria-invalid="true">
        <label for="cbError" class="checkbox-label">Checkbox (error)</label>
      </div>
    </div><!-- ./checkbox -->

    <!-- checkbox chips -->
    <div class="container-lg" style="margin-bottom: 50px; padding-bottom: 50px; border-bottom: 1px solid #ccc;">
      <h2>Checkbox Chips</h2>
      <p></p>

      <div class="chip-container">
        <!-- chip -->
        <div class="chip">
          <input class="chip__input" type="checkbox" id="cbChip1">
          <label class="chip__label" for="cbChip1">Crunchips</label>
        </div><!-- ./chip -->
      </div>

      <br><br>

      <div class="chip-container">
        <!-- chip -->
        <div class="chip">
          <input class="chip__input" type="checkbox" id="cbReadonly" readonly>
          <label class="chip__label" for="cbReadonly">Readonly</label>
        </div><!-- ./chip -->
      </div>

      <br><br>

      <div class="chip-container">
        <!-- chip -->
        <div class="chip">
          <input class="chip__input" type="checkbox" id="cbReadonlyChecked" readonly checked>
          <label class="chip__label" for="cbReadonlyChecked">Readonly</label>
        </div><!-- ./chip -->
      </div>

      <br><br>

      <div class="chip-container">
        <!-- chip -->
        <div class="chip">
          <input class="chip__input" type="checkbox" id="cbChipDisabled" disabled>
          <label class="chip__label" for="cbChipDisabled">Disabled</label>
        </div><!-- ./chip -->
      </div>

      <br><br>

      <div class="chip-container">
        <!-- chip -->
        <div class="chip">
          <input class="chip__input" type="checkbox" id="cbChipDisabledChecked" disabled checked>
          <label class="chip__label" for="cbChipDisabledChecked">Disabled</label>
        </div><!-- ./chip -->
      </div>

      <br><br>

      <div class="chip-container">
        <!-- chip -->
        <div class="chip">
          <input class="chip__input is--invalid" type="checkbox" id="cbChipDisabledError" aria-invalid="true">
          <label class="chip__label" for="cbChipDisabledError">Crunchips</label>
        </div><!-- ./chip -->
      </div>
    </div><!-- ./checkbox -->

    <!-- checkbox switch -->
    <div class="container-lg" style="margin-bottom: 50px; padding-bottom: 50px; border-bottom: 1px solid #ccc;">
      <h2>Checkbox Switch</h2>
      <p></p>

      <div class="switch">
        <input type="checkbox" class="switch__checkbox" id="cbSwitch" checked>
        <label class="switch__label" for="cbSwitch">
          <span class="switch__text">Öffentlich</span>
        </label>
      </div>

      <br><br>

      <div class="switch">
        <input type="checkbox" class="switch__checkbox" id="cbSwitchDisabled" disabled>
        <label class="switch__label" for="cbSwitchDisabled">
          <span class="switch__text">Öffentlich</span>
        </label>
      </div>

      <br><br>

      <div class="switch">
        <input type="checkbox" class="switch__checkbox" id="cbSwitchDisabledChecked" disabled checked>
        <label class="switch__label" for="cbSwitchDisabledChecked">
          <span class="switch__text">Öffentlich</span>
        </label>
      </div>
    </div><!-- ./checkbox switch -->

    <!-- button -->
    <div class="container-lg" style="margin-bottom: 50px; padding-bottom: 50px; border-bottom: 1px solid #ccc;">
      <h2>Button</h2>
      <p></p>

      <button class="btn btn--primary">Primary</button>
      <br><br>
      <button class="btn btn--primary" disabled>Primary</button>
      <br><br>
      <button class="btn btn--primary btn--big">Primary big</button>

      <br><br><br>

      <button class="btn btn--secondary">Secondary</button>
      <br><br>
      <button class="btn btn--secondary" disabled>Secondary</button>
      <br><br>
      <button class="btn btn--secondary btn--big">Secondary big</button>

      <br><br><br>

      <button class="btn-icon">
        <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16"
             height="16" fill="currentColor">
          <path
              d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z"/>
        </svg>
        <span class="visually-hidden">Edit</span>
      </button>

      <br><br>

      <button class="btn-icon" disabled>
        <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16"
             height="16" fill="currentColor">
          <path
              d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z"/>
        </svg>
        <span class="visually-hidden">Edit</span>
      </button>

      <br><br><br>
      <button class="anchor">Link style</button>
    </div><!-- ./button -->

    <!-- error message -->
    <div class="container-lg" style="margin-bottom: 50px; padding-bottom: 50px; border-bottom: 1px solid #ccc;">
      <h2>Error message</h2>
      <p>Add aria-invalid="false" and aria-describedby="idOfMessage" to connect the input to the error message.</p>

      <div class="form__row">
        <div class="form__field">
          <label for="someIput" class="label">
            <span class="label__text">Label</span>
            <span class="label__hint">Lorem ipsum dolor sit amet</span>
          </label>

          <input id="someIput" type="text" class="text-input is--invalid" aria-invalid="true"
                 aria-describedby="exampleErrorMsg">

          <!-- error message -->
          <div class="field__error" id="exampleErrorMsg">
            <svg aria-hidden="true" focusable="false" class="field__error-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="16" height="16" fill="currentColor">
              <path
                  d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"/>
            </svg>

            <span class="field__error-text">This is an error message</span>
          </div><!-- ./error message -->
        </div>
      </div>
    </div><!-- ./error message -->

    <!-- password -->
    <div class="container-lg" style="margin-bottom: 50px; padding-bottom: 50px; border-bottom: 1px solid #ccc;">
      <h2>Password Show/Hide</h2>
      <p>
        Add the class 'is--visible' to the button to switch icons.<br>
        Change the type of the textbox from password to text to show the password.
      </p>

      <div class="form__row">
        <div class="form__field">
          <label for="tbPassword" class="label">
            <span class="label__text">Password</span>
            <span class="label__hint">Must contain ...</span>
          </label>

          <!-- reveal -->
          <div class="password-reveal">
            <input id="tbPassword" type="password" class="text-input">

            <button class="password-reveal__toggle" type="button">
              <svg class="password-reveal__svg" aria-describedby="password-info"
                   xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" role="img" width="20"
                   fill="currentColor">
                <path
                    d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"/>
              </svg>

              <svg class="password-reveal__svg" aria-describedby="password-info"
                   xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" role="img" width="20"
                   fill="currentColor">
                <path
                    d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"/>
              </svg>

              <!-- todo vuejs: switch text when on show/hide -->
              <span id="password-info" class="visually-hidden">Show</span>
            </button>
          </div><!-- ./reveal -->
        </div>
      </div>
    </div><!-- ./password -->
  </div>
</template>

<script>
import AppNavigation from "@/components/AppNavigation";
export default {
  name: "Form",
  components: {AppNavigation}
}
</script>

<style scoped>

h2 {
  margin: 0 0 30px 0;
}

</style>