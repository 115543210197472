<template>
  <div class="hero">
    <div class="hero__inner">
      <div class="hero__text">
        <h1 class="hero__headline">Mach Dich bereit für Deinen Mystery Coffee.</h1>
        <div class="hero__links">
          <a class="btn btn--secondary btn--big hero__link" href="#/registration">Jetzt Profil erstellen</a>
          <a class="btn btn--primary btn--big hero__link" href="#/login">Einloggen</a>
        </div>
      </div>
      <div class="hero__visual">
        <img src="/img/dummy/2d.png" alt="Illustration of Mystery Cup">
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
  @import "../assets/scss/config/variables";

  .hero {
    width: 100%;
    background: var(--client-bg-color);
    padding: $space-lg $page-padding;

    @media (min-width: $breakpoint-md-up) {
      padding: $space-xl $page-padding;
    }
  }

  .hero__inner {
    max-width: $container-sm;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 3.125rem;

    @media (min-width: $breakpoint-xl-up) {
      flex-direction: row;
      max-width: $container-lg;
    }
  }

  .hero__text {
    flex: 1 1 0;
  }

  .hero__headline {
    font-size: 2rem;
    color: var(--client-fg-color);

    @media (min-width: $breakpoint-md-up) {
      font-size: 3.75rem;
    }
  }

  .hero__links {
    display: flex;
    gap: 1.25rem;
    flex-direction: column;
    align-items: stretch;
    margin: 50px 0 0 0;

    @media (min-width: $breakpoint-sm-up) {
      align-items: center;
      flex-direction: row;
    }
  }

  .hero__link {
    flex: 1 1 50%;
    border-color: var(--client-fg-color);
    border-width: 2px;

    &.btn--primary {
      &:hover,
      &:focus {
        border-color: var(--client-bg-color-hover);
      }
    }
  }

  // visual
  .hero__visual {
    flex: 1 1 0;

    display: inline-flex;
    justify-content: center;
  }
</style>