<template>
  <div>
    <AppNavigation/>

    <div class="container-lg">
      <div style="border: 1px solid #ccc; border-radius: 20px; padding: 30px; text-align: center; margin: 0 0 30px 0;">
        container-lg
      </div>
    </div>

    <div class="container-md">
      <div style="border: 1px solid #ccc; border-radius: 20px; padding: 30px; text-align: center;margin: 0 0 30px 0;">
        container-md
      </div>
    </div>

    <div class="container-sm">
      <div style="border: 1px solid #ccc; border-radius: 20px; padding: 30px; text-align: center;margin: 0 0 30px 0;">
        container-sm
      </div>
    </div>

    <div class="container-xs">
      <div style="border: 1px solid #ccc; border-radius: 20px; padding: 30px; text-align: center;margin: 0 0 30px 0;">
        container-xs
      </div>
    </div>
  </div>
</template>

<script>
import AppNavigation from "@/components/AppNavigation";
export default {
  name: "Container",
  components: {AppNavigation}
}
</script>

<style scoped>

</style>