<template>
  <div>
    <AppNavigation/>

    <div class="container-lg" style="margin-bottom: 50px; padding-bottom: 50px; border-bottom: 1px solid #ccc;">
      <h1>Spinner</h1>
      <!--
      <p>Initially aria-busy should be set to false. Set aria-busy="true" if an area is loading.</p>-->
      <br><br><br>

      <!-- spinner -->
      <div class="spinner" aria-live="polite" aria-busy="true">
        <div class="visually-hidden">Loading</div>

        <div class="spinner__overlay" aria-hidden="true">
          <div class="spinner__circle"></div>
        </div>
      </div><!-- ./spinner -->
    </div>
  </div>
</template>

<script>
import AppNavigation from "@/components/AppNavigation";
export default {
  name: "Spinner",
  components: {AppNavigation}
}
</script>

<style scoped>

</style>