<template>
  <div class="card">
    <div class="card__header">
      <div class="card__headline">
        <!-- todo: check with Timar 'is-headline' span vs. h2, h3 - can we pass which kind of element to be used? -->
        <span class="card__headline-label">Aktuelles Match</span>
      </div>
    </div>

    <div class="card__body">
      <div class="match">
        <img class="match__img" src="https://picsum.photos/id/1005/120/120" width="120" height="120" alt="User name">
        <span class="match__name">Theresa Ryan</span>
        <span class="match__info">Content Creation</span>
      </div>
    </div>

    <div class="card__footer card__footer--push-down">
      <div class="card__actions">
        <a class="btn btn--secondary" href="mailto:">E-Mail senden</a>
        <a class="btn btn--primary" href="#">Profil ansehen</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
  @import "../assets/scss/config/variables";

</style>