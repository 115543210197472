<template>
  <div class="page">
    <HeaderProfile />

    <main class="main has--gray-bg">
      <div class="profile">
        <div class="profile__inner">
          <ProfileNavigation/>

          <!-- grid -->
          <div class="grid">
            <div class="grid__headline">
              <h1 class="h2">Freunde einladen</h1>
            </div>

            <div class="grid__item grid__item--big">
              <div class="card">
                <form>
                  <!-- error -->
                  <div aria-live="assertive" aria-atomic="true">
                    <div class="alert alert--error" tabindex="-1" aria-labelledby="error-summary-heading">
                      <svg class="alert__icon" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg"
                           viewBox="0 0 576 512" fill="currentColor" width="24" height="22">
                        <path
                            d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"/>
                      </svg>
                      <div class="alert__message" id="error-summary-heading">
                      <span class="alert__headline">
                        Error
                      </span>
                        <p class="alert__description">
                          Lorem ipsum dolor sit amet.
                        </p>
                      </div>
                    </div>
                  </div><!-- ./error -->

                  <!-- todo: use tag input component vuejs -->
                  <!-- email -->
                  <div class="form__row">
                    <div class="form__field">
                      <label for="invite-email" class="label">
                        <span class="label__text">E-Mail-Adressen deiner Freunde</span>
                      </label>
                      <input type="email" id="invite-email" name="email" class="text-input" autocapitalize="none" spellcheck="false" required>
                    </div>
                  </div><!-- ./email -->

                  <!-- language -->
                  <div class="form__row">
                    <fieldset class="fieldset">
                      <legend class="label">
                        <span class="label__text">Sprache des E-Mail Textes</span>
                      </legend>

                      <div class="chip-container">
                        <div class="chip">
                          <input class="chip__input" type="radio" name="emailLanguage" id="chipDE" checked>
                          <label class="chip__label" for="chipDE">Deutsch</label>
                        </div>
                        <div class="chip">
                          <input class="chip__input" type="radio" name="emailLanguage" id="chipEN">
                          <label class="chip__label" for="chipEN">Englisch</label>
                        </div>
                        <div class="chip">
                          <input class="chip__input" type="radio" name="emailLanguage" id="chipES">
                          <label class="chip__label" for="chipES">Spanisch</label>
                        </div>
                      </div>
                    </fieldset>
                  </div><!-- ./language -->

                  <!-- mail subject -->
                  <div class="form__row">
                    <div class="form__field">
                      <label for="invite-subject" class="label">
                        <span class="label__text">Betreff</span>
                      </label>
                      <input type="text" id="invite-subject" class="text-input" required>
                    </div>
                  </div><!-- ./mail subject -->

                  <!-- mail body -->
                  <div class="form__row">
                    <div class="form__field">
                      <label for="invite-body" class="label">
                        <span class="label__text">Nachricht</span>
                      </label>
                      <textarea id="invite-body" rows="3" class="text-input" required>Hallo Josef...</textarea>
                    </div>
                  </div><!-- ./mail body -->

                  <!-- submit -->
                  <div class="form__submit form__submit--inline">
                    <button type="submit" class="btn btn--primary">Einladung senden</button>
                  </div><!-- ./submit -->
                </form>
              </div>
            </div>

            <div class="grid__item grid__item--small">
              <div class="card">
                <div class="invite-status copy-text">
                  <!--<h2>
                    <span class="invite-status__text">Noch keine Freunde eingeladen</span>
                  </h2>-->

                  <h2>
                    <span class="invite-status__count">3</span>
                    <span class="invite-status__text">Eingeladene Freunde</span>
                  </h2>

                  <img class="invite-status__img" src="https://via.placeholder.com/230x280" width="230" height="280" alt="Illustration of Mystery Cup">

                  <p>
                    Je mehr Leute mitmachen, desto spannender die Matches.
                  </p>
                </div>
              </div>
            </div>
          </div><!-- ./grid -->
        </div>
      </div>
    </main>

    <Support/>
    <Footer/>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import HeaderProfile from "@/components/HeaderProfile";
import ProfileNavigation from "@/components/ProfileNavigation";
import Support from "@/components/Support";

export default {
  name: "Master",
  components: {ProfileNavigation, HeaderProfile, Footer, Support}
}
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/config/variables";
  @import "../../assets/scss/components/profile";

  .invite-status {
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    align-items: center;
    text-align: center;
    max-width: 19.375rem;
    margin: 0 auto;
  }

  .invite-status__count {
    font-size: 3.75rem;
    display: block;
  }

  .invite-status__text {
    font-weight: 700;
    font-size: 1rem;
    display: block;
  }
</style>