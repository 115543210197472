<template>
  <!-- header -->
  <header class="header">
    <div class="header__inner">
      <!-- logo -->
      <a class="logo" href="/">
        <img class="logo__image" src="/img/dummy/logoipsum-logo.svg" alt="Customer X Logo">
      </a><!-- ./logo -->

      <div class="header__actions">
        <!-- dropdown -->
        <div class="dropdown">
          <button class="dropdown__toggle" aria-haspopup="true" v-on:click="isVisible = !isVisible" :aria-expanded="[isVisible]" type="button" aria-label="Menu">
            <img class="dropdown__img" src="https://picsum.photos/id/447/40/40" alt="">

            <span class="dropdown__text dropdown--username">Joshua Morris</span>

            <svg class="dropdown__icon" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="12" fill="currentColor">
              <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"/>
            </svg>
          </button>

          <div class="dropdown__menu" :class="{ 'is--visible': isVisible }">
            <ul class="dropdown__list">
              <li class="dropdown__item">
                <a class="dropdown__link" href="#">
                  <svg class="dropdown__link-icon" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16" fill="currentColor">
                    <path d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"/>
                  </svg>
                  <span>Einstellungen</span>
                </a>
              </li>
              <li class="dropdown__item">
                <button class="dropdown__link" type="button">
                  <svg class="dropdown__link-icon" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16" fill="currentColor">
                    <path d="M180 448H96c-53 0-96-43-96-96V160c0-53 43-96 96-96h84c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H96c-17.7 0-32 14.3-32 32v192c0 17.7 14.3 32 32 32h84c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm117.9-303.1l77.6 71.1H184c-13.3 0-24 10.7-24 24v32c0 13.3 10.7 24 24 24h191.5l-77.6 71.1c-10.1 9.2-10.4 25-.8 34.7l21.9 21.9c9.3 9.3 24.5 9.4 33.9.1l152-150.8c9.5-9.4 9.5-24.7 0-34.1L353 88.3c-9.4-9.3-24.5-9.3-33.9.1l-21.9 21.9c-9.7 9.6-9.3 25.4.7 34.6z"/>
                  </svg>
                  <span>Ausloggen</span>
                </button>
              </li>
            </ul>
          </div>
        </div><!-- ./dropdown -->
      </div>
    </div>
  </header><!-- ./header -->
</template>

<script>
export default {
  data() {
    return {
      isVisible: false
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/scss/config/variables";
  @import "../assets/scss/components/header";
</style>