<template>
  <div class="support">
    <button class="support-trigger" aria-haspopup="true" :aria-expanded="[isVisible]" v-on:click="showSupport" type="button">
      <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="15" height="23" fill="currentColor">
        <path d="M202.021 0C122.202 0 70.503 32.703 29.914 91.026c-7.363 10.58-5.093 25.086 5.178 32.874l43.138 32.709c10.373 7.865 25.132 6.026 33.253-4.148 25.049-31.381 43.63-49.449 82.757-49.449 30.764 0 68.816 19.799 68.816 49.631 0 22.552-18.617 34.134-48.993 51.164-35.423 19.86-82.299 44.576-82.299 106.405V320c0 13.255 10.745 24 24 24h72.471c13.255 0 24-10.745 24-24v-5.773c0-42.86 125.268-44.645 125.268-160.627C377.504 66.256 286.902 0 202.021 0zM192 373.459c-38.196 0-69.271 31.075-69.271 69.271 0 38.195 31.075 69.27 69.271 69.27s69.271-31.075 69.271-69.271-31.075-69.27-69.271-69.27z"/>
      </svg>
      <span class="visually-hidden">Hilfe und Support</span>
    </button>

    <!-- support box -->
    <!--
        todo: Status Messages: success & error message (transition?) & close on ESC
     -->
    <transition name="support">
      <div v-if="isVisible" id="supportWindow" class="support__dialog" role="dialog" aria-labelledby="supportHeadline" aria-modal="true" tabindex="-1">
        <div class="support__header">
          <h2 id="supportHeadline" class="support__headline">Support</h2>

          <button class="btn-icon support__close" v-on:click="isVisible = !isVisible" type="button">
            <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" width="20" height="20" fill="currentColor">
              <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/>
            </svg>
            <span class="visually-hidden">Close</span>
          </button>
        </div>
        <div class="support__body copy-text">
          <p>
            <b>Gerne helfen wir dir bei Fragen, Anregungen und Problemen.</b>
          </p>
          <p>
            Schau in unseren <a href="#">FAQ’s</a> oder schreibe uns eine Nachricht.
          </p>
        </div>
        <div class="support__form">
          <form class="form" novalidate>
            <fieldset class="fieldset">
              <legend class="visually-hidden">Supportanfrage</legend>

              <div class="form__row">
                <div class="form__field">
                  <label for="support-message" class="label">
                    <span class="label__text">Deine Nachricht</span>
                  </label>

                  <textarea id="support-message" rows="3" class="text-input"></textarea>
                </div>
              </div>

              <div class="form__submit">
                <button class="btn btn--primary full--width" type="submit">Jetzt senden</button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </transition><!-- ./support box -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false
    }
  },
  // define methods under the `methods` object
  methods: {
    showSupport: function () { // event
      this.isVisible = true;

      // todo: focus the textarea (not working because the transition is not finished
      /*
        const supportWindow = document.getElementById('supportWindow');
        const supportText = supportWindow.getElementsByTagName('textarea');
        supportText.focus();
      */
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/scss/config/variables";

  .support-trigger {
    position: fixed;
    bottom: 25px;
    right: 20px;

    border-radius: 50%;
    background: $gray-mm;
    border: 2px solid #fff;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    transition: $transition; // 0.25

    &:active {
      transform: scale(0.9);
    }

    &:hover,
    &:focus {
      background: var(--client-bg-color);
      color: var(--client-fg-color);
    }
  }

  .support__dialog {
    display: block;
    position: fixed;
    bottom: 20px;
    max-height: calc(100vh - 2.5rem);
    overflow: auto;
    left: $page-padding;
    right: $page-padding;
    z-index: $zindex-support;
    background: #fff;
    box-shadow: $box-shadow-lg;
    padding: $box-padding-lg;
    border-radius: $border-radius-lg;

    transform-origin: right bottom;
    transition: $transition; // 0.25

    @media (min-width: $breakpoint-sm-up) {
      left: auto;
      max-width: 23.125rem;
    }

    /*opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: scale3d(0.9, 0.9, 0.9);
    transform-origin: right bottom;
    transition: $transition; // 0.25
    will-change: transform, opacity, visibility;

    &.is--visible {
      opacity: 1;
      visibility: visible;
      transform: scale3d(1, 1, 1);
      pointer-events: all;
    }*/
  }

  .support__header {
    display: flex;
    align-items: flex-start;
    gap: 10px;
  }

  .support__headline {

  }

  .support__close {
    margin-left: auto;
    transform: translate(15px, -15px);
  }

  .support__body {
    margin: 1.875rem 0 0 0;
  }

  .support__form {
    margin: 1.875rem 0 0 0;
  }

  // transition
  .support-enter-active,
  .support-leave-active {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }

  .support-enter,
  .support-leave-to {
    opacity: 0;
    transform: scale3d(0.9, 0.9, 0.9);
  }
</style>