<template>
  <div class="page">
    <HeaderInProcess/>

    <main class="main has--gradient">
      <!-- split panel -->
      <div class="split-panel">
        <div class="split-panel__inner">
          <div class="split-panel__item split-panel--visual">
            <img class="split-panel__image" src="https://via.placeholder.com/400x400" width="400" height="400" alt="">
          </div>
          <div class="split-panel__item split-panel--content">
            <div class="split-panel__content">
              <h1 class="split-panel__headline h2">Hoppla!</h1>

              <!-- form -->
              <form class="form" novalidate>
                <!-- info -->
                <div aria-live="polite" aria-atomic="true">
                  <div class="alert alert--info" tabindex="-1" aria-labelledby="info-summary-heading">
                    <svg class="alert__icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                         fill="currentColor" width="24" height="24">
                      <path
                          d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"/>
                    </svg>
                    <div class="alert__message" id="info-summary-heading">
                      <p class="alert__description">
                        Deine Authentifizierung ist noch nicht abgeschlossen. Bitte fordere erneut einen Link an, um Dein Profil freizuschalten.
                      </p>
                    </div>
                  </div>
                </div><!-- ./info -->

                <!-- email -->
                <div class="form__row">
                  <div class="form__field">
                    <label for="authentification-email" class="label">
                      <span class="label__text">E-Mail-Adresse</span>
                    </label>
                    <input type="email" id="authentification-email" name="email" autocomplete="email" class="text-input"
                           autocapitalize="none" spellcheck="false" required>
                  </div>
                </div><!-- ./email -->

                <!-- submit -->
                <div class="form__submit">
                  <button type="submit" class="btn btn--primary">Link erneut senden</button>
                </div><!-- ./submit -->
              </form><!-- ./form -->
            </div>
          </div>
        </div>
      </div><!-- ./split panel -->
    </main>

    <Support/>
    <Footer/>
  </div>
</template>

<script>
import HeaderInProcess from "@/components/HeaderInProcess";
import Footer from "@/components/Footer";
import Support from "@/components/Support";

export default {
  name: "Master",
  components: {HeaderInProcess, Footer, Support}
}
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/config/variables";
  @import "../../assets/scss/components/split-panel";
</style>