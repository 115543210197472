<template>
  <div class="page">
    <HeaderInProcess/>

    <main class="main has--gradient">
      <!-- split panel -->
      <div class="split-panel">
        <div class="split-panel__inner">
          <div class="split-panel__item split-panel--visual">
            <img class="split-panel__image" src="https://via.placeholder.com/400x400" width="400" height="400" alt="">
          </div>
          <div class="split-panel__item split-panel--content">
            <div class="split-panel__content">
              <!-- message -->
              <div class="panel-message">
                <h1 class="panel-message__headline h2">
                  Danke für Deine Anmeldung.
                </h1>
                <p>
                  Nun musst du die Anmeldung nur noch über den Link bestätigen, den wir dir per E-Mail geschickt haben.
                </p>
                <p>
                  Bitte beachte, dass es einige Zeit dauern kann, bis die E-Mail bei dir ankommt. Überprüfe bitte zur
                  Sicherheit auch deinen Spam-Ordner.
                </p>
              </div><!-- ./message -->
            </div>
          </div>
        </div>
      </div><!-- ./split panel -->
    </main>

    <Support/>
    <Footer/>
  </div>
</template>

<script>
import HeaderInProcess from "@/components/HeaderInProcess";
import Footer from "@/components/Footer";
import Support from "@/components/Support";

export default {
  name: "PasswordSuccess",
  components: {HeaderInProcess, Footer, Support}
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/config/variables";
@import "../../assets/scss/components/split-panel";
</style>