<template>
  <div class="content">
    <div class="content__inner copy-text">
      <h1>Datenschutz&shy;erklärung</h1>

      <p>
        Gültig ab 24.03.2020
      </p>
      <h2>Geltungsbereich und Gegenstand der Datenschutzerklärung</h2>
      <p>
        Gegenstand dieser Datenschutzerklärung ist die Information, ob und inwieweit wir personenbezogenen Daten über
        unsere Website, abrufbar unter https://www.mysteryminds.com/ erheben und zu welchem Zweck diese verarbeitet
        werden. Damit erfüllen wir unsere Pflicht nach Art. 13 und Art. 14 der DS-GVO, Sie ausführlich und transparent
        zu der Erhebung personenbezogener Daten auf unserer Webseite zu informieren. Diese Datenschutzerklärung findet
        ausschließlich Anwendung auf die vorgenannte Website. Soweit auf andere Seiten verlinkt wird, haben wir weder
        Einfluss noch Kontrolle auf die verlinkten Inhalte und die dortigen Datenschutzbestimmungen. Wir empfehlen, die
        Datenschutzerklärungen auf den Websites zu prüfen, um feststellen zu können, ob und in welchem Umfang
        personenbezogene Daten erhoben, verarbeitet, genutzt und / oder Dritten zugänglich gemacht werden.
      </p>
      <h2>Definitionen und Begriffsbestimmungen</h2>
      <p>
        Diese Datenschutzerklärung beruht auf den Begrifflichkeiten, die durch den Europäischen Richtlinien- und
        Verordnungsgeber beim Erlass der Datenschutz-Grundverordnung (DS-GVO) verwendet wurden. Unsere
        Datenschutzerklärung soll sowohl für die öffentlichkeit als auch für unsere Kunden und Geschäftspartner einfach
        lesbar und verständlich sein. Um dies zu gewährleisten, möchten wir vorab die verwendeten Begrifflichkeiten
        erläutern.
      </p>
      <p>
        Wir verwenden in dieser Datenschutzerklärung unter anderem die folgenden Begriffe:
      </p>
      <h3>Personenbezogene Daten</h3>
      <p>
        Alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden
        "betroffene Person") beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder
        indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu
        Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen identifiziert werden
        kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder
        sozialen Identität dieser natürlichen Person sind.
      </p>
      <h3>Betroffene Person</h3>
      <p>
        Betroffene Person ist jede identifizierte oder identifizierbare natürliche Person, deren personenbezogene Daten
        von dem für die Verarbeitung Verantwortlichen verarbeitet werden.
      </p>
      <h3>Verarbeitung</h3>
      <p>
        Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche
        Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen, die Organisation, das
        Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die
        Offenlegung durch übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die
        Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.
      </p>
      <h3>Dritter</h3>
      <p>
        Dies ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, außer der betroffenen
        Person, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter der unmittelbaren
        Verantwortung des Verantwortlichen oder des Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu
        verarbeiten.
      </p>
      <h3>Verantwortlicher oder für die Verarbeitung Verantwortlicher</h3>
      <p>
        Verantwortlicher oder für die Verarbeitung Verantwortlicher ist die natürliche oder juristische Person, Behörde,
        Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der
        Verarbeitung von personenbezogenen Daten entscheidet. Sind die Zwecke und Mittel dieser Verarbeitung durch das
        Unionsrecht oder das Recht der Mitgliedstaaten vorgegeben, so kann der Verantwortliche beziehungsweise können
        die bestimmten Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten vorgesehen
        werden.
      </p>
      <h2>Name und die Kontaktdaten des Verantwortlichen</h2>
      <p>
        Mystery Minds GmbH, Barer Str. 71, 80799 München, vertreten durch die Geschäftsführer Christoph Drebes, Stefan
        Melbinger, E-Mail: <a href="mailto:info@mysteryminds.com">info@mysteryminds.com</a>
      </p>
      <h2>Kategorien der personenbezogenen Daten, die auf unserer Webseite verarbeitet werden</h2>
      <h3>Webseitenaufruf</h3>
      <h4>a) Personenbezogene Daten</h4>
      <p>
        IP-Adresse, Cookie-Kennung
      </p>
      <h4>b) Beschreibung</h4>
      <p>
        Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. Wir erheben jedoch beim Aufruf
        unserer Website sogenannte Nutzungsdaten und speichern diese in einer Protokolldatei (sog. Logdatei). Zu diesen
        Zugriffsdaten zählt auch die IP-Adresse. Daneben werden in der Logdatei der Name der abgerufenen Website, die
        aufgerufene Datei, das Datum und die Uhrzeit des Abrufs, die übertragene Datenmenge und Meldung über den
        erfolgreichen Abruf, der Browsertyp nebst Version, das Betriebssystem, die sog. Referrer URL (die zuvor besuchte
        Seite) sowie der anfragende Provider gespeichert. Aus diesen Daten ist ein Rückschluss auf Ihre Person aber
        nicht möglich.
      </p>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
@import "../assets/scss/config/variables";

.content {
  padding: $space-lg $page-padding;

  @media (min-width: $breakpoint-md-up) {
    padding: $space-xl $page-padding;
  }
}

.content__inner {
  max-width: $container-md;
  margin: 0 auto;
}
</style>