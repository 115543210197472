<template>
  <div class="page">
    <HeaderProfile />

    <main class="main has--gray-bg">
      <div class="profile">
        <div class="profile__inner">
          <ProfileNavigation/>

          <h1 class="visually-hidden">Profil bearbeiten</h1>

          <!--
          <div class="profile__top-bar">
            <div class="profile__status">tbd: completion rate</div>
            <a class="btn btn--primary" href="#">Profil ansehen</a>
          </div>
          -->

          <!-- grid -->
          <div class="grid">
            <div class="grid__item grid__item--big">
              <div class="profile__top-bar">
                <div class="profile__status">tbd: completion rate</div>
                <a class="btn btn--primary" href="#">Profil ansehen</a>
              </div>
            </div>

            <!-- picture -->
            <div class="grid__item grid__item--big">
              <!-- todo: make sure to set the role group and labelledby attribute correctly -->
              <div class="card" role="group" aria-labelledby="userPicture">
                <!-- header -->
                <div class="card__header">
                  <div id="userPicture" class="card__headline">
                    <span class="card__headline-label">Profilbild</span>
                  </div>
                  <div class="card__edit">
                    <div class="card__status">
                      <svg class="card__status-svg" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="18" height="16" fill="currentColor">
                        <title>Öffentlich sichtbar</title>
                        <path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"/>
                      </svg>
                      <span class="visually-hidden">Öffentlich sichtbar</span>
                    </div>

                    <button class="btn-icon">
                      <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16" height="16" fill="currentColor">
                        <path d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z"/>
                      </svg>
                      <span class="visually-hidden">Bearbeiten</span>
                    </button>
                  </div>
                </div><!-- ./header -->

                <div class="card__body">
                  <img class="image--circle" src="https://picsum.photos/id/447/170/170" width="170" height="170" alt="User name">
                </div>
              </div>
            </div>

            <div class="grid__item grid__item--big">
              <!-- todo: make sure to set the role group and labelledby attribute correctly -->
              <!-- todo: add the class is--editing if the user edits a block -->
              <div class="card is--editing" role="group" aria-labelledby="userPictureEdit">
                <!-- header -->
                <div class="card__header">
                  <div id="userPictureEdit" class="card__headline">
                    <span class="card__headline-label">Profilbild</span>
                  </div>
                  <div class="card__edit">
                    <button class="btn-icon">
                      <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" width="14" height="20" fill="currentColor">
                        <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/>
                      </svg>
                      <span class="visually-hidden">Schließen</span>
                    </button>
                  </div>
                </div><!-- ./header -->

                <!-- body -->
                <div class="card__body">
                  <form class="profile-picture">
                    <!-- upload -->
                    <div class="upload">
                      <fieldset class="fieldset">
                        <legend class="fieldset__legend">Lade dein eigenens Bild hoch</legend>

                        <!--<img class="image--circle" src="https://via.placeholder.com/170x170" width="170" height="170" alt="Illustration of Mystery Cup">-->

                        <button class="upload__button" type="button">
                            <svg class="upload__icon" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="45" height="36" fill="currentColor">
                              <path d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zm-139.9 63.7l-10.8 10.8c-9.6 9.6-25.2 9.3-34.5-.5L320 266.1V392c0 13.3-10.7 24-24 24h-16c-13.3 0-24-10.7-24-24V266.1l-32.4 34.5c-9.3 9.9-24.9 10.1-34.5.5l-10.8-10.8c-9.4-9.4-9.4-24.6 0-33.9l92.7-92.7c9.4-9.4 24.6-9.4 33.9 0l92.7 92.7c9.4 9.4 9.4 24.6.1 33.9z"/>
                            </svg>
                            <span class="upload-text">
                              Datei hochladen
                            </span>
                          </button>
                      </fieldset>
                    </div><!-- ./upload -->

                    <!-- todo alex: depending on the number of avatars reduce the size of the items -->
                    <!-- avatar picker -->
                    <div class="avatar-picker">
                      <fieldset class="fieldset">
                        <legend class="fieldset__legend">Oder wähle einen Avatar aus</legend>

                        <ul class="avatar-picker__grid">
                          <li class="avatar-picker__item">
                            <input id="avatar1" type="radio" name="avatars" class="avatar-picker__input">
                            <label for="avatar1" class="avatar-picker__label">
                              <img class="avatar-picker__img" src="https://picsum.photos/id/110/100/100" width="100" height="100" alt="User name">
                              <span class="visually-hidden">Smiley face</span>
                            </label>
                          </li>
                          <li class="avatar-picker__item">
                            <input id="avatar2" type="radio" name="avatars" class="avatar-picker__input">
                            <label for="avatar2" class="avatar-picker__label">
                              <img class="avatar-picker__img" src="https://picsum.photos/id/200/100/100" width="100" height="100" alt="User name">
                              <span class="visually-hidden">Smiley face</span>
                            </label>
                          </li>
                          <li class="avatar-picker__item">
                            <input id="avatar3" type="radio" name="avatars" class="avatar-picker__input">
                            <label for="avatar3" class="avatar-picker__label">
                              <img class="avatar-picker__img" src="https://picsum.photos/id/1000/100/100" width="100" height="100" alt="User name">
                              <span class="visually-hidden">Smiley face</span>
                            </label>
                          </li>
                          <li class="avatar-picker__item">
                            <input id="avatar4" type="radio" name="avatars" class="avatar-picker__input">
                            <label for="avatar4" class="avatar-picker__label">
                              <img class="avatar-picker__img" src="https://picsum.photos/id/999/100/100" width="100" height="100" alt="User name">
                              <span class="visually-hidden">Smiley face</span>
                            </label>
                          </li>
                          <li class="avatar-picker__item">
                            <input id="avatar5" type="radio" name="avatars" class="avatar-picker__input">
                            <label for="avatar5" class="avatar-picker__label">
                              <img class="avatar-picker__img" src="https://picsum.photos/id/500/100/100" width="100" height="100" alt="User name">
                              <span class="visually-hidden">Smiley face</span>
                            </label>
                          </li>

                          <li class="avatar-picker__item">
                            <input id="avatar6" type="radio" name="avatars" class="avatar-picker__input">
                            <label for="avatar6" class="avatar-picker__label">
                              <img class="avatar-picker__img" src="https://picsum.photos/id/600/100/100" width="100" height="100" alt="User name">
                              <span class="visually-hidden">Smiley face</span>
                            </label>
                          </li>

                          <li class="avatar-picker__item">
                            <input id="avatar7" type="radio" name="avatars" class="avatar-picker__input">
                            <label for="avatar7" class="avatar-picker__label">
                              <img class="avatar-picker__img" src="https://picsum.photos/id/700/100/100" width="100" height="100" alt="User name">
                              <span class="visually-hidden">Smiley face</span>
                            </label>
                          </li>

                          <li class="avatar-picker__item">
                            <input id="avatar8" type="radio" name="avatars" class="avatar-picker__input">
                            <label for="avatar8" class="avatar-picker__label">
                              <img class="avatar-picker__img" src="https://picsum.photos/id/800/100/100" width="100" height="100" alt="User name">
                              <span class="visually-hidden">Smiley face</span>
                            </label>
                          </li>
                        </ul>
                      </fieldset>
                    </div><!-- ./avatar picker -->
                  </form>
                </div>
                <!-- ./body -->

                <!-- submit -->
                <div class="card__submit">
                  <button class="btn btn--primary" type="submit">Speichern</button>
                </div>
                <!-- ./submit -->
              </div>
            </div>
            <!-- ./picture -->
          </div><!-- ./grid -->
        </div>
      </div>
    </main>

    <ModalUpload/>
    <Support/>
    <Footer/>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import HeaderProfile from "@/components/HeaderProfile";
import ProfileNavigation from "@/components/ProfileNavigation";
import Support from "@/components/Support";
import ModalUpload from "@/components/ModalUpload";

export default {
  name: "Master",
  components: {ModalUpload, ProfileNavigation, HeaderProfile, Footer, Support}
}
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/config/variables";
  @import "../../assets/scss/components/profile";

  .profile-picture {
    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint-md-up) {
      align-items: flex-start;
      flex-direction: row;
    }
  }

  // upload
  .upload {
    padding: 0 0 1.875rem 0;

    @media (min-width: $breakpoint-md-up) {
      flex: 0 0 16.875rem; // 270
      max-width: 16.875rem;
      padding: 0 1.875rem 0 0;
    }
  }

  .upload__button {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    box-shadow: $box-shadow-sm;
    gap: 0.625rem;
    padding: 0.9375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    &:hover,
    &:focus {
      transition: $transition;

      .upload__icon {
        transform: scale(1.1) translateY(-3px);
      }
    }
  }

  .upload__icon {
    width: 45px;
    height: auto;
    color: $gray-middle;
    transition: $transition;
  }

  .upload-text {
    font-size: 0.875rem;
    text-align: center;
  }

  // avatar picker
  .avatar-picker {
    flex: 1;
    padding: 1.875rem 0 0 0;

    @media (min-width: $breakpoint-md-up) {
      border-left: 1px solid $gray-middle;
      padding: 0 0 0 30px;
    }
  }

  .avatar-picker__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(4.0625rem, 1fr)); // 65
    gap: 1.875rem;
    list-style: none;
    padding: 0;
  }

  .avatar-picker__item {
  }

  .avatar-picker__input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;

    &:hover ~ .avatar-picker__label,
    &:checked ~ .avatar-picker__label {
      opacity: 1;
      //transform: scale(1.03);
    }

    &:checked ~ .avatar-picker__label,
    &:focus ~ .avatar-picker__label {
      box-shadow: 0 0 0 2px #fff, 0 0 0 6px var(--client-bg-color);
    }
  }

  .avatar-picker__label {
    cursor: pointer;
    display: inline-block;
    user-select: none;
    border-radius: 50%;
    opacity: 0.85;
    transition: $transition;
  }

  .avatar-picker__img {
    border-radius: 50%;
  }
</style>