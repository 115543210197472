<template>
  <div class="page">
    <Header/>

    <main class="main">
      <ContentBlock/>
    </main>

    <Support/>
    <Footer/>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Support from "@/components/Support";
import ContentBlock from "@/components/ContentBlock";

export default {
  name: "DataPrivacy",
  components: {ContentBlock, Header, Footer, Support}
}
</script>

<style lang="scss" scoped>

</style>