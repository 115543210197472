<template>
  <div class="page">
    <HeaderInProcess/>

    <main class="main has--gradient">
      <!-- split panel -->
      <div class="split-panel">
        <div class="split-panel__inner">
          <div class="split-panel__item split-panel--visual">
            <img class="split-panel__image" src="https://via.placeholder.com/400x400" width="400" height="400" alt="">
          </div>
          <div class="split-panel__item split-panel--content">
            <div class="split-panel__content">
              <!-- message -->
              <div class="panel-message">
                <h1 class="panel-message__headline h2">
                  Schade, dass Du gehst!
                </h1>
                <p>
                  Wir wären Dir dankbar, wenn Du uns kurz verrätst, was wir in Zukunft noch besser machen könnten oder was wir grundsätzlich ändern sollten.
                </p>
              </div><!-- ./message -->

              <!-- form -->
              <form class="form" novalidate>
                <div class="form__row">
                  <fieldset class="fieldset">
                    <legend class="visually-hidden">
                        Warum wirst du Mystery Coffee nicht mehr nutzen?
                    </legend>

                    <div class="form__row">
                      <div class="form__field">
                        <input type="checkbox" class="checkbox" name="feedbackReasons" id="feedbackReason1">
                        <label for="feedbackReason1" class="checkbox-label">Die Anwendung ist zu unübersichtlich.</label>
                      </div>
                      <div class="form__field">
                        <input type="checkbox" class="checkbox" name="feedbackReasons" id="feedbackReason2">
                        <label for="feedbackReason2" class="checkbox-label">Die Matches waren uninteressant.</label>
                      </div>
                      <div class="form__field">
                        <input type="checkbox" class="checkbox" name="feedbackReasons" id="feedbackReason3">
                        <label for="feedbackReason3" class="checkbox-label">Ich habe schlechte Erfahrungen gemacht.</label>
                      </div>
                      <div class="form__field">
                        <input type="checkbox" class="checkbox" name="feedbackReasons" id="feedbackReason4">
                        <label for="feedbackReason4" class="checkbox-label">Ich sehe den Mehrwert nicht.</label>
                      </div>
                      <div class="form__field">
                        <input type="checkbox" class="checkbox" name="feedbackReasons" id="feedbackReason5">
                        <label for="feedbackReason5" class="checkbox-label">Die Anwendung ist zu zeitintensiv.</label>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <!-- message -->
                <div class="form__row">
                  <div class="form__field">
                    <label for="feedback-message" class="label">
                      <span class="label__text">Andere Gründe (optional)</span>
                    </label>
                    <textarea id="feedback-message" rows="3" class="text-input"></textarea>
                  </div>
                </div><!-- ./message -->

                <!-- submit -->
                <div class="form__submit">
                  <button type="submit" class="btn btn--primary">Feedback senden</button>
                </div><!-- ./submit -->
              </form><!-- ./form -->
            </div>
          </div>
        </div>
      </div><!-- ./split panel -->
    </main>

    <Support/>
    <Footer/>
  </div>
</template>

<script>
import HeaderInProcess from "@/components/HeaderInProcess";
import Footer from "@/components/Footer";
import Support from "@/components/Support";

export default {
  name: "OffboardingFeedback",
  components: {HeaderInProcess, Footer, Support}
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/config/variables";
@import "../../assets/scss/components/split-panel";
</style>