<template>
  <!--
    info: set aria-current="page" to the currently active page to indicate which page is active to screenreader users
    Add class is--paused to pause button...
  -->
  <!-- navigation -->
  <div class="navigation">
    <nav class="navigation__nav" aria-label="Key.Mainnavigation">
      <ul class="navigation__list">
        <li class="navigation__item">
          <a class="navigation__link is--active" href="#" aria-current="page">
            <svg class="navigation__icon" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 512 512" height="20" fill="currentColor">
              <path
                  d="M296 32h192c13.255 0 24 10.745 24 24v160c0 13.255-10.745 24-24 24H296c-13.255 0-24-10.745-24-24V56c0-13.255 10.745-24 24-24zm-80 0H24C10.745 32 0 42.745 0 56v160c0 13.255 10.745 24 24 24h192c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24zM0 296v160c0 13.255 10.745 24 24 24h192c13.255 0 24-10.745 24-24V296c0-13.255-10.745-24-24-24H24c-13.255 0-24 10.745-24 24zm296 184h192c13.255 0 24-10.745 24-24V296c0-13.255-10.745-24-24-24H296c-13.255 0-24 10.745-24 24v160c0 13.255 10.745 24 24 24z"/>
            </svg>
            <span class="navigation__text">Dashboard</span>
          </a>
        </li>
        <li class="navigation__item">
          <a class="navigation__link" href="#">
            <svg class="navigation__icon" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 640 512" height="20" fill="currentColor">
              <path
                  d="M192 256c61.9 0 112-50.1 112-112S253.9 32 192 32 80 82.1 80 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C51.6 288 0 339.6 0 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zM480 256c53 0 96-43 96-96s-43-96-96-96-96 43-96 96 43 96 96 96zm48 32h-3.8c-13.9 4.8-28.6 8-44.2 8s-30.3-3.2-44.2-8H432c-20.4 0-39.2 5.9-55.7 15.4 24.4 26.3 39.7 61.2 39.7 99.8v38.4c0 2.2-.5 4.3-.6 6.4H592c26.5 0 48-21.5 48-48 0-61.9-50.1-112-112-112z"/>
            </svg>
            <span class="navigation__text">Matches</span>
          </a>
        </li>
        <li class="navigation__item">
          <a class="navigation__link" href="#">
            <svg class="navigation__icon" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 448 512" height="20" fill="currentColor">
              <path
                  d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"/>
            </svg>
            <span class="navigation__text">Profil</span>
          </a>
        </li>
        <li class="navigation__item">
          <a class="navigation__link" href="#">
            <svg class="navigation__icon" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 512 512" height="20" fill="currentColor">
              <path
                  d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z"/>
            </svg>

            <span class="navigation__text">Einladen</span>
          </a>
        </li>
      </ul>
    </nav><!-- ./navigation -->

    <!-- pause -->
    <button class="pause" type="button"> <!-- is--paused -->
      <svg class="pause__icon" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 512 512" height="20" fill="currentColor">
        <path
            d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm-16 328c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v160zm112 0c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v160z"/>
      </svg>
      <span class="pause__text">Teilnahme pausieren</span>
    </button><!-- ./pause -->
  </div><!-- ./navigation -->
</template>

<script>
export default {
  name: "ProfileNavigation"
};
</script>

<style scoped lang="scss">
@import "../assets/scss/config/variables";

.navigation {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 $space-md 0;

  @media (min-width: $breakpoint-lg-up) {
    border-bottom: 2px solid $gray-middle;
    justify-content: space-between;
    margin: 0 0 $space-lg 0;
  }
}

.navigation__nav {
  position: fixed;
  bottom: -1px;
  z-index: $zindex-navigation;
  left: 0;
  width: 100%;
  box-shadow: $box-shadow-sm;
  background: #fff;

  @media (min-width: $breakpoint-lg-up) {
    position: static;
    width: auto;
    display: block;
    box-shadow: none;
    background: transparent;
  }
}

.navigation__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  @media (min-width: $breakpoint-lg-up) {
    display: flex;
    align-items: center;
    gap: 40px;
  }
}

.navigation__item {

}

.navigation__link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  font-size: 0.75rem;
  text-decoration: none;
  color: $gray-dark;
  position: relative;
  padding: 15px 5px;
  transition: $transition; // 0.25

  @media (min-width: $breakpoint-lg-up) {
    flex-direction: row;
    font-size: 1rem;
  }

  &:hover,
  &:focus {
    color: $gray-mm;
    //box-shadow: none;
  }

  &::after {
    opacity: 0.5;
    content: '';
    position: absolute;
    left: auto;
    right: auto;
    bottom: -3px;
    width: 100%;
    border-bottom: 4px solid transparent;
  }

  &[aria-current="page"],
  &.is--active {
    color: $gray-mm;

    &::after {
      opacity: 1;
      border-color: $gray-mm;
    }
  }
}

.navigation__icon {
  height: 20px;
  width: auto;
}

.navigation__text {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}

// <button> pause
.pause {
  color: $gray-mm;
  display: inline-flex;
  gap: 10px;
  align-items: center;

  &:hover,
  &:focus {
    .pause__text {
      text-decoration: underline;
      text-underline-offset: 0.125rem;
    }
  }

  &.is--paused {
    color: $danger-color;
  }

  @media (min-width: $breakpoint-lg-up) {
    padding: 10px;
  }
}

.pause__icon {

}

.pause__text {
}
</style>
