<template>
  <!-- modal -->
  <!--
    todo: check against: https://a11y-dialog.netlify.app/
    https://www.smashingmagazine.com/2021/07/accessible-dialog-from-scratch/
  -->
  <div class="modal-backdrop is--active"><!-- is--active -->
    <div class="modal">
      <div class="modal__box" role="dialog" id="modal1" aria-labelledby="modalHeadline" aria-modal="true" tabindex="-1"><!-- is--hidden -->
        <div class="modal__header">
          <h2 class="modal__headline" id="modalHeadline">Pause bearbeiten</h2>

          <button class="btn-icon modal__close" type="button">
            <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" width="20" height="20" fill="currentColor">
              <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/>
            </svg>
            <span class="visually-hidden">Close</span>
          </button>
        </div>
        <div class="modal__body">
          <!-- success -->
          <div aria-live="assertive" aria-atomic="true">
            <div class="alert alert--success" tabindex="-1" aria-labelledby="success-summary-heading">
              <svg class="alert__icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                   fill="currentColor" width="24" height="24">
                <path
                    d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"/>
              </svg>
              <div class="alert__message" id="success-summary-heading">
                <span class="alert__headline">
                  Pause beendet. Du bist jetzt wieder dabei!
                </span>
              </div>
            </div>
          </div><!-- ./success -->

          <div class="copy-text">
            <p>Noch <b>23 Tage</b> bis zum Ende deiner Pause</p>
          </div>
        </div>
        <div class="modal__action">
          <button class="btn btn--secondary">Pause verlängern</button>
          <button class="btn btn--primary">Pause beenden</button>
        </div>
      </div>
    </div>
  </div><!-- ./modal -->
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
@import "../assets/scss/config/variables";

</style>