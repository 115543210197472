<template>
  <div class="card card--dark">
    <div class="card__body">
      <div class="invite">
        <h2 class="invite__headline h3">
          Deine Freunde sollten Mystery Coffee auch mal ausprobieren?
        </h2>
      </div>
    </div>
    <div class="card__footer">
      <div class="card__actions">
        <button class="btn btn--tertiary" type="button">Freunde einladen</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
  @import "../assets/scss/config/variables";

  .invite {
    padding: 0.625rem 0;
    max-width: 19.375rem; // 310
    margin: 0 auto;
  }

  .invite__headline {
    text-align: center;
    line-height: 1.5;
  }
</style>