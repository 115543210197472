<template>
  <div class="card">
    <!-- header -->
    <div class="card__header">
      <div class="card__headline">
        <!-- todo: check with Timar 'is-headline' span vs. h2, h3 - can we pass which kind of element to be used? -->
        <span class="card__headline-label">Match erhalten am: 01.05.2021</span>
      </div>
    </div><!-- ./header -->

    <!-- body -->
    <div class="card__body">
      <div class="match">
        <img class="match__img" src="https://picsum.photos/id/1027/120/120" width="120" height="120" alt="User name">
        <span class="match__name">Theresa Ryan</span>
        <span class="match__info">Content Creation</span>
        <!-- todo: after how feedback will be given is clear, add "(check icon) Yes we met" / "( x ) No, we did not meet..." -->
        <div class="match__links">
          <a class="match__link" href="mailto:">
            <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="18" height="18" fill="currentColor">
              <path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"/>
            </svg>
            <span class="visually-hidden">E-Mail senden</span>
          </a>
          <a class="match__link" href="#">
            <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="16" height="18" fill="currentColor">
              <path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"/>
            </svg>
            <span class="visually-hidden">Profil ansehen</span>
          </a>
        </div>
      </div>
    </div><!-- ./body -->

    <!-- footer -->
    <div class="card__footer card__footer--push-down">
      <div class="card__rating">
        <span class="card__rating-label">Hat ein Treffen stattgefunden?</span>
        <div class="card__rating-actions">
          <button class="btn btn--secondary" v-on:click="isVisible = !isVisible" type="button">Nein</button>
          <button class="btn btn--primary" v-on:click="isVisible = !isVisible" type="button">Ja</button>
        </div>
      </div>
    </div><!-- ./footer -->

    <!-- todo: switch content for "YES" rating. Not stars but dropdown with answers. -->
    <!-- rating -->
    <div class="rating" :class="{ 'is--visible': isVisible }" :aria-hidden="[!isVisible]">
      <div class="rating__header">
        <span class="rating__headline h3">Warum hat das Treffen nicht stattgefunden?</span>
      </div>

      <div class="rating__body">
        <div class="form__row">
          <div class="form__field">
            <label for="ratingReason" class="label visually-hidden">
              <span class="label__text">Begründung</span>
            </label>

            <select id="ratingReason" class="select">
              <option value="Option 1">Teilnehmer hat abgesagt</option>
              <option value="Option 2">Keine Zeit</option>
              <option value="Option 3">...</option>
            </select>
          </div>
        </div>

        <button class="btn btn--tertiary rating__submit" type="button">Begründung abgeben</button>
      </div>

      <div class="rating__footer copy-text">
        <p>
          Die Information ist <b>anonym</b> und dient nur zu statistischen Zwecken.
        </p>
      </div>

      <button class="btn-icon rating__close" v-on:click="isVisible = !isVisible" type="button">
        <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" width="20" height="20" fill="currentColor">
          <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/>
        </svg>
        <span class="visually-hidden">Close</span>
      </button>
    </div><!-- ./rating -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/scss/config/variables";
  @import "../assets/scss/components/rating";
</style>