<template>
  <div>
    <AppNavigation/>

    <!-- accordion -->
    <div class="container-lg" style="margin-bottom: 50px; padding-bottom: 50px; border-bottom: 1px solid #ccc;">
      <h1>Accordion</h1>
      <p>
        Set aria-expanded="true" when the accordion is open.<br>
        Remove the hidden attribute from the connected accordion__panel when the accordion is open.
      </p>

      <br><br><br>

      <!-- accordion -->
      <div class="accordion">
        <!-- item -->
        <div class="accordion__item">
          <h2 class="accordion__headline">
            <button class="accordion__toggle" aria-expanded="false" aria-controls="faq1" id="idfaq1">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr

              <svg class="accordion__icon" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="12" fill="currentColor">
                <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"/>
              </svg>
            </button>
          </h2>
          <div class="accordion__panel" id="faq1" role="region" aria-labelledby="idfaq1" hidden>
            <p>some content</p>
          </div>
        </div><!-- ./item -->
        <div class="accordion__item">
          <h2 class="accordion__headline">
            <button class="accordion__toggle" aria-expanded="true" aria-controls="faq2" id="idfaq2">
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet

              <svg class="accordion__icon" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="12" fill="currentColor">
                <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"/>
              </svg>
            </button>
          </h2>
          <div class="accordion__panel copy-text" id="faq2" role="region" aria-labelledby="idfaq2">
            <p>some content</p>
          </div>
        </div>
        <div class="accordion__item">
          <h2 class="accordion__headline">
            <button class="accordion__toggle" aria-expanded="false" aria-controls="faq3" id="idfaq3">
              At vero eos et accusam et justo duo dolores et ea rebum clita kasd gubergren no sea takimata sanctus est Lorem ipsum dolor sit amet

              <svg class="accordion__icon" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="12" fill="currentColor">
                <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"/>
              </svg>
            </button>
          </h2>
          <div class="accordion__panel copy-text" id="faq3" role="region" aria-labelledby="idfaq3" hidden>
            <p>some content</p>
          </div>
        </div>
      </div><!-- ./accordion -->
    </div><!-- ./accordion -->

    <!-- dropdown -->
    <div class="container-lg" style="margin-bottom: 50px; padding-bottom: 50px; border-bottom: 1px solid #ccc;">
      <h1>Dropdown</h1>
      <p>
        Set aria-expanded to true, if the menu is visible.<br>
        Remove the hidden attribute from the menu to show it.
      </p>

      <br><br><br>

      <div style="display: flex; flex-direction: column; gap: 150px; align-items: flex-start;">
        <!-- dropdown -->
        <div class="dropdown">
          <button class="dropdown__toggle" aria-haspopup="true" aria-expanded="false" type="button">
            <img class="dropdown__img" src="/img/dummy/user.jpg" alt="">

            <span class="dropdown__text dropdown--username">Joshua Morris</span>

            <svg class="dropdown__icon" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="12" fill="currentColor">
              <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"/>
            </svg>
          </button>

          <div class="dropdown__menu">
            <ul class="dropdown__list">
              <li class="dropdown__item">
                <a class="dropdown__link" href="#">
                  <svg class="dropdown__link-icon" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16" fill="currentColor">
                    <path d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"/>
                  </svg>
                  <span>Einstellungen</span>
                </a>
              </li>
              <li class="dropdown__item">
                <a class="dropdown__link" href="#">
                  <svg class="dropdown__link-icon" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16" fill="currentColor">
                    <path d="M180 448H96c-53 0-96-43-96-96V160c0-53 43-96 96-96h84c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H96c-17.7 0-32 14.3-32 32v192c0 17.7 14.3 32 32 32h84c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm117.9-303.1l77.6 71.1H184c-13.3 0-24 10.7-24 24v32c0 13.3 10.7 24 24 24h191.5l-77.6 71.1c-10.1 9.2-10.4 25-.8 34.7l21.9 21.9c9.3 9.3 24.5 9.4 33.9.1l152-150.8c9.5-9.4 9.5-24.7 0-34.1L353 88.3c-9.4-9.3-24.5-9.3-33.9.1l-21.9 21.9c-9.7 9.6-9.3 25.4.7 34.6z"/>
                  </svg>
                  <span>Ausloggen</span>
                </a>
              </li>
            </ul>
          </div>
        </div><!-- ./dropdown -->

        <!-- dropdown -->
        <div class="dropdown">
          <button class="dropdown__toggle" aria-haspopup="true" aria-expanded="false" type="button">
            <span class="dropdown__text">DE</span>

            <svg class="dropdown__icon" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="12" fill="currentColor">
              <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"/>
            </svg>
          </button>

          <div class="dropdown__menu" hidden>
            <ul class="dropdown__list">
              <li class="dropdown__item">
                <a class="dropdown__link" href="#">DE</a>
              </li>
              <li class="dropdown__item">
                <a class="dropdown__link" href="#">EN</a>
              </li>
              <li class="dropdown__item">
                <a class="dropdown__link" href="#">ES</a>
              </li>
            </ul>
          </div>
        </div><!-- ./dropdown -->
      </div>
    </div><!-- ./dropdown -->

    <!-- modal -->
    <div class="container-lg" style="margin-bottom: 50px; padding-bottom: 50px; border-bottom: 1px solid #ccc;">
      <h1>Modal</h1>
      <p>
        When modal is opened:<br>
        1) add class 'has--modal' to the body<br>
        2) add the class is--active' to '.modal-backdrop'<br>
        3) remove the class 'is--hidden' from '.modal'<br>
        4) modal window needs to be closed on ESC
        5) set focus to 'modal__box'
      </p>

      <br><br><br>

      <!--
        todo: check about motion driven design
      -->
      <!-- modal -->
      <div class="modal-backdrop"><!-- is--active -->
        <div class="modal">
          <div class="modal__box" role="dialog" id="modal1" aria-labelledby="modalHeadline" aria-modal="true" tabindex="-1"><!-- is--hidden -->
            <div class="modal__header">
              <button class="btn-icon modal__back" type="button">
                <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20" height="20" fill="currentColor">
                  <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"/>
                </svg>
                <span class="visually-hidden">Back</span>
              </button>

              <h2 class="modal__headline" id="modalHeadline">Headline</h2>

              <button class="btn-icon modal__close" type="button">
                <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" width="20" height="20" fill="currentColor">
                  <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/>
                </svg>
                <span class="visually-hidden">Close</span>
              </button>
            </div>
            <div class="modal__body">
              Have a nice day!
            </div>
            <div class="modal__action">
              <button class="btn btn--secondary">Secondary</button>
              <button class="btn btn--primary">Primary</button>
            </div>
          </div>
        </div>
      </div><!-- ./modal -->

      <!-- modal showcase -->
      <div class="modal">
        <div class="modal__box">
          <div class="modal__header">
            <button class="btn-icon modal__back" type="button">
              <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20" height="20" fill="currentColor">
                <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"/>
              </svg>
              <span class="visually-hidden">Back</span>
            </button>

            <h2 class="modal__headline" id="modalHeadline2">Headline</h2>

            <button class="btn-icon modal__close" type="button">
              <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" width="20" height="20" fill="currentColor">
                <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/>
              </svg>
              <span class="visually-hidden">Close</span>
            </button>
          </div>
          <div class="modal__body">
            Have a nice day!
          </div>
          <div class="modal__action">
            <button class="btn btn--secondary">Secondary</button>
            <button class="btn btn--primary">Primary</button>
          </div>
        </div>
      </div><!-- ./modal showcase -->
    </div><!-- ./modal -->
  </div>
</template>

<script>
import AppNavigation from "@/components/AppNavigation";
export default {
  name: "UIComponents",
  components: {AppNavigation}
}
</script>

<style scoped lang="scss">
  @import "../assets/scss/config/variables";
  @import "../assets/scss/components/accordion";
</style>