<template>
  <div>
    <AppNavigation/>
    <TestimonialCarousel/>
  </div>
</template>

<script>
import AppNavigation from "@/components/AppNavigation";
import TestimonialCarousel from "@/components/TestimonialCarousel";

export default {
  name: "Testimonials",
  components: {TestimonialCarousel, AppNavigation}
}
</script>

<style scoped>

</style>