<template>
  <div class="card">
    <div class="card__header">
      <div class="card__headline">
        <!-- todo: check with Timar 'is-headline' span vs. h2, h3 - can we pass which kind of element to be used? -->
        <span class="card__headline-label">Nächstes Match</span>
      </div>
    </div>

    <div class="card__body">
      <div class="match">
        <img class="match__img" src="https://picsum.photos/id/1011/120/120" width="120" height="120" alt="">
        <span class="match__date">Findet am 01.11.2021 statt</span>
        <button class="match__suspend anchor" type="button">Aussetzen</button>
      </div>
    </div>
    <div class="card__footer card__footer--push-down">
      <div class="card__actions">
        <button class="btn btn--primary" type="button">Teilnahme pausieren</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
  @import "../assets/scss/config/variables";


</style>