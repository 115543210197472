<template>
  <div class="card">
    <div class="user">
      <img class="user__img" src="https://picsum.photos/id/447/170/170" width="170" height="170" alt="User name">

      <div class="user__info">
        <h1 class="user__name">Joshua Morris</h1>
        <div class="user__location">
          <img class="user__location-icon" src="/img/icons/map-marker-alt.svg" height="20" alt="">
          <span>München</span>
        </div>
        <p class="user__about">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
        </p>
      </div>

      <a class="user__edit btn-icon" href="#">
        <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16" height="16" fill="currentColor">
          <path d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z"/>
        </svg>

        <span class="visually-hidden">Profil bearbeiten</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
  @import "../assets/scss/config/variables";

  .user {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1.875rem;
    max-width: 19.375rem;
    margin: 0 auto;
    text-align: center;
    position: relative;

    @media (min-width: $breakpoint-md-up) {
      align-items: flex-start;
      flex-direction: row;
      max-width: none;
      text-align: left;
    }
  }

  .user__img {
    flex: 0 0 10.625rem;
    width: 10.625rem;
    border-radius: 50%;
  }

  .user__info {
    flex: 1;
    display: flex;
    gap: 1.25rem;
    flex-direction: column;
  }

  .user__name {
    font-size: 1.625rem;
    line-height: 1.3;

    @media (min-width: $breakpoint-md-up) {
      padding: 0 3.125rem 0 0; // create space for the icon
    }
  }

  .user__location {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.625rem;
    margin: 0 0 0.625rem 0;

    @media (min-width: $breakpoint-md-up) {
      justify-content: flex-start;
    }
  }

  .user__location-icon {
    width: auto;
    height: 1.25rem;
    flex: 0 0 1.25rem;
  }

  .user__edit {
    position: absolute;
    right: 0;
    top: 0;
  }
</style>