<template>
  <div class="page">
    <Header/>

    <main class="main has--gray-bg">
      <!-- wizard -->
      <div class="wizard">
        <div class="wizard__inner">
          <!-- progress -->
          <div class="wizard__progress">
            <progress class="progress" id="file" value="50" max="100">42%</progress>
          </div><!-- ./progress -->

          <!-- form -->
          <form class="wizard__question">
            <div class="form__row">
              <div class="form__field">
                <label for="ddlCompany" class="label label--big">
                  <span class="label__text">In welchen Unternehmensbereich bist Du tätig?</span>
                </label>
                <select id="ddlCompany" class="select" required>
                  <option value="Option 1">Option 1</option>
                  <option value="Option 2">Option 2</option>
                  <option value="Option 3">Option 3</option>
                  <option value="Option 4">Option 4</option>
                  <option value="Option 5">Option 5</option>
                  <option value="Option length">Option that has too long of a value to fit</option>
                </select>
              </div>
            </div>

            <!-- navigation -->
            <div class="wizard__navigation">
              <a class="btn btn--secondary wizard__link" href="#/wizardStep1">
                <svg class="wizard__link-icon" aria-labelledby="wizardBack" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" width="20" height="24" fill="currentColor">
                  <title id="wizardBack">Zurück</title>
                  <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"/>
                </svg>
                <span class="wizard__link-text">Zurück</span>
              </a>
              <a class="btn btn--primary wizard__link" href="#/wizardStep3">
                <svg class="wizard__link-icon" aria-labelledby="wizardNext" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" width="20" height="24" fill="currentColor">
                  <title id="wizardNext">Weiter</title>
                  <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"/>
                </svg>
                <span class="wizard__link-text">Weiter</span>
              </a>
            </div><!-- ./navigation -->
          </form><!-- ./form -->
        </div>
      </div><!-- ./split panel -->
    </main>

    <Support/>
    <Footer/>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Support from "@/components/Support";

export default {
  name: "Registration",
  components: {Header, Footer, Support}
}
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/config/variables";
  @import "../../assets/scss/components/wizard";

</style>