<template>
  <div>
    <AppNavigation />
    <ContactInfo/>
  </div>
</template>

<script>
import ContactInfo from "@/components/ContactInfo";
import AppNavigation from "@/components/AppNavigation";

export default {
  name: "Contact",
  components: {AppNavigation, ContactInfo}
}
</script>

<style scoped>

</style>