<template>
  <nav class="app__nav">
    <ul>
      <li>
        <router-link :to="{ name: 'Home'}">Home</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'Typography'}">Typography</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'Spinner'}">Spinner</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'Container'}">Container</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'Form'}">Form</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'UIComponents'}">UI Components</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'Hero'}">Hero</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'About'}">AboutSteps</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'Contact'}">Contact</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'Content'}">Content</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'Testimonials'}">Testimonials</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
  .app__nav {
    //position: fixed;
    //z-index: 1000;
    //top: 0;
    //left: 0;
    width: 100%;
    padding: 10px;
    background: #231F20;
    color: #fff;
    margin: 0 0 50px 0;

    ul {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      list-style: none;
    }

    li {
      padding: 0 10px;
    }

    a {
      color: inherit;
      font-weight: 700;
      text-decoration: none;
      font-size: 14px;
    }
  }
</style>