<template>
  <!-- modal -->
  <div class="modal-backdrop is--active"><!-- is--active -->
    <div class="modal">
      <div class="modal__box" role="dialog" id="modal1" aria-labelledby="modalHeadline" aria-modal="true" tabindex="-1"><!-- is--hidden -->
        <div class="modal__header">
          <button class="btn-icon modal__back" type="button">
            <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20" height="20" fill="currentColor">
              <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"/>
            </svg>
            <span class="visually-hidden">Back</span>
          </button>

          <h2 class="modal__headline" id="modalHeadline">Pause verlängern</h2>

          <button class="btn-icon modal__close" type="button">
            <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" width="20" height="20" fill="currentColor">
              <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/>
            </svg>
            <span class="visually-hidden">Close</span>
          </button>
        </div>
        <!-- todo: check about form tag & content replacement in modal nuxt. -->
        <form>
          <div class="modal__body">
              <div class="form__row">
                <fieldset class="fieldset">
                  <legend class="label">
                    <span class="label__text">Pause verlängern um</span>
                  </legend>

                  <div class="chip-container">
                    <div class="chip">
                      <input class="chip__input" type="radio" name="setPause" id="chip1Week">
                      <label class="chip__label" for="chip1Week">1 Woche</label>
                    </div>
                    <div class="chip">
                      <input class="chip__input" type="radio" name="setPause" id="chip2Weeks">
                      <label class="chip__label" for="chip2Weeks">2 Wochen</label>
                    </div>
                    <div class="chip">
                      <input class="chip__input" type="radio" name="setPause" id="chip1Month">
                      <label class="chip__label" for="chip1Month">1 Monat</label>
                    </div>
                  </div>
                </fieldset>
              </div>

              <!-- datepicker -->
              <div class="form__row">
                <div class="form__field">
                  <label for="extend-date" class="label">
                    <span class="label__text">Pause verlängern bis</span>
                  </label>
                  <input type="text" id="extend-date" class="text-input">
                </div>
              </div><!-- ./datepicker -->

              <!-- notify me -->
              <div class="form__row">
                <div class="form__field">
                  <input type="checkbox" id="pause-notification" class="checkbox">
                  <label for="pause-notification" class="checkbox-label label--small">
                    <span>Benachrichtige mich, wenn meine Pause abgelaufen ist.</span>
                  </label>
                </div>
              </div><!-- ./notify me -->
          </div>
          <div class="modal__action">
            <button class="btn btn--primary" type="submit">Jetzt verlängern</button>
          </div>
        </form>
      </div>
    </div>
  </div><!-- ./modal -->
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
@import "../assets/scss/config/variables";

</style>