<template>
  <div class="page">
    <HeaderInProcess/>

    <main class="main has--gradient">
      <!-- split panel -->
      <div class="split-panel">
        <div class="split-panel__inner">
          <div class="split-panel__item split-panel--visual">
            <img class="split-panel__image image--circle" src="https://picsum.photos/id/447/350/350" width="350"
                 height="350" alt="">
          </div>
          <div class="split-panel__item split-panel--content">
            <div class="split-panel__profile">
              <h1 class="split-panel__headline">Joshua Morris</h1>

              <div class="user-info">
                <!-- location -->
                <div class="user-info__row">
                  <div class="user-info__header">
                    <img class="user-info__icon" src="/img/icons/map-marker-alt.svg" alt="">
                    <h2 class="user-info__headline">Standort</h2>
                  </div>

                  <p>
                    München
                  </p>
                </div><!-- ./location -->

                <!-- languages -->
                <div class="user-info__row">
                  <div class="user-info__header">
                    <h2 class="user-info__headline">Sprachen</h2>
                  </div>

                  <ul class="chips-list">
                    <li class="chips-list__item">Deutsch</li>
                    <li class="chips-list__item">Englisch</li>
                  </ul>
                </div><!-- ./languages -->

                <!-- professional interests -->
                <div class="user-info__row">
                  <div class="user-info__header">
                    <h2 class="user-info__headline">Berufliche Interessen</h2>
                  </div>

                  <ul class="chips-list">
                    <li class="chips-list__item">Weiterbildung</li>
                    <li class="chips-list__item">Training</li>
                  </ul>
                </div><!-- ./professional interests -->

                <!-- about -->
                <div class="user-info__row">
                  <div class="user-info__header">
                    <h2 class="user-info__headline">Über mich</h2>
                  </div>

                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                    labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et
                    ea rebum.
                  </p>
                </div><!-- ./about -->

                <!-- linked in -->
                <div class="user-info__row">
                  <div class="user-info__header">
                    <h2 class="user-info__headline">LinkedIn</h2>
                  </div>

                  <p>
                    <a class="anchor" href="https://www.linkedin.com" target="_blank">www.linkedin.com/d0p35h1t</a>
                  </p>
                </div><!-- ./entry date -->

                <a class="btn btn--primary user-info__email" href="mailto:joshua.morris@company.com">Nachricht schreiben</a>
              </div>
            </div>
          </div>
        </div>
      </div><!-- ./split panel -->
    </main>

    <Support/>
    <Footer/>
  </div>
</template>

<script>
import HeaderInProcess from "@/components/HeaderInProcess";
import Footer from "@/components/Footer";
import Support from "@/components/Support";

export default {
  name: "Master",
  components: {HeaderInProcess, Footer, Support}
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/config/variables";
@import "../../assets/scss/components/split-panel";

.user-info {

}

.user-info__row {
  margin: 0 0 $space-md 0;
}

.user-info__header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 0 $space-xs 0;
}

.user-info__icon {
  width: auto;
  height: 20px;
  flex: 0 0 20px;
}

.user-info__headline {
  //color: $gray-dark;
  font-size: 1rem;
}

.user-info__email {
  display: block;
  margin: $space-sm 0 0 0;

  @media (min-width: $breakpoint-sm-up) {
    display: inline-block;
  }
}
</style>