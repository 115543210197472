<template>
  <div class="page">
    <HeaderInProcess/>

    <main class="main has--gradient">
      <!-- split panel -->
      <div class="split-panel">
        <div class="split-panel__inner">
          <div class="split-panel__item split-panel--visual">
            <img class="split-panel__image" src="https://via.placeholder.com/400x400" width="400" height="400" alt="">
          </div>
          <div class="split-panel__item split-panel--content">
            <div class="split-panel__content">
              <!-- message -->
              <div class="panel-message">
                <h1 class="panel-message__headline h2">
                  <svg class="panel-message__icon" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="24" height="24">
                    <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"/>
                  </svg>
                  Passwort geändert
                </h1>
                <p>
                  Du kannst dich jetzt mit deinem neuen Passwort einloggen.
                </p>
                <a class="btn btn--primary" href="#/login">Zum Login</a>
              </div><!-- ./message -->
            </div>
          </div>
        </div>
      </div><!-- ./split panel -->
    </main>

    <Support/>
    <Footer/>
  </div>
</template>

<script>
import HeaderInProcess from "@/components/HeaderInProcess";
import Footer from "@/components/Footer";
import Support from "@/components/Support";

export default {
  name: "PasswordSuccess",
  components: {HeaderInProcess, Footer, Support}
}
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/config/variables";
  @import "../../assets/scss/components/split-panel";
</style>